.p-toast {
    opacity: 1;
    .p-toast-message {
        background: $overlay !important;
        border-radius: 0;
        color: $text !important;
        border-left: .1rem solid !important;
        border-top: .1rem solid !important;
        border-right: .1rem solid !important;
        border-bottom: .1rem solid !important;
    }

    .p-toast-message.p-toast-message-success {
        border-left-color: $success-color !important;
        border-top-color: $success-color !important;
        border-right-color: $success-color !important;
        border-bottom-color: $success-color !important;
        .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
            color: $success-color;
        }
        .p-toast-icon-close svg {
            color: $success-color !important;
        }
        .p-toast-icon-close:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 1px transparent !important;
        }
    }

    .p-toast-message.p-toast-message-error {
        border-left-color: $error-color !important;
        border-top-color: $error-color !important;
        border-right-color: $error-color !important;
        border-bottom-color: $error-color !important;
        .p-toast-message-content .p-toast-message-icon.p-icon {
            width: 3rem !important;
            height: 3rem !important;
        }
        .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
            color: $error-color;
        }
        .p-toast-icon-close svg {
            color: $error-color !important;
        }
        .p-toast-icon-close:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 1px transparent !important;
        }
    }

    .p-toast-message.p-toast-message-info {
        border-left-color: $info-color !important;
        border-top-color: $info-color !important;
        border-right-color: $info-color !important;
        border-bottom-color: $info-color !important;
        .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
            color: $info-color;
        }
        .p-toast-icon-close svg {
            color: $info-color !important;
        }
        .p-toast-icon-close:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 1px transparent !important;
        }
    }

    .p-toast-message .p-toast-message-content {
        display: flex;
        align-items: center;
        .p-toast-message-text + div {
            height: 5rem;
            position: relative;
            top: -.5rem;
        }
    }

    .p-toast-summary {
        font-weight: 500 !important;
    }
    .p-toast-detail {
        line-height: 20px;    
    }
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
    width: 4rem !important;
    height: 4rem !important;
    padding-right:.5rem;
}

.p-toast .p-toast-message .p-toast-icon-close {
    width: unset;
    
}