
.pi {
    color: $icon;
}

.pi.callout {
    color: $callout;
}

.p-icon {
    width:1rem;
}

.icon {
    color: $icon;
}

.icon.primary {
    background: $icon !important;
}

.primary + i {
    color: $icon;
}

.icon.icon-logo {
    mask-image: url(./../images/layout-skew-grid.svg);
    //background: $app-icon-color;
    margin-right: 0;
    width: 2rem;
    //height: 2rem;  
}
.filter-green{
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}
.icon.icon-login {
    mask-image: url(./../images/log-in.svg);
}
.icon.icon-dashboard {
    mask-image: url(./../images/heat-grid.svg);
}
.icon.icon-members {
    mask-image: url(./../images/people.svg);
}
.icon.icon-chartbook {
    mask-image: url(./../images/grouped-bar-chart.svg);
}
.icon.icon-analytics {
    mask-image: url(./../images/function.svg);
}
.icon.icon-scatter {
    mask-image: url(./../images/scatter-plot.svg);
}
.icon.icon-pie {
    mask-image: url(./../images/pie-chart.svg);
}
.icon.icon-timeline {
    mask-image: url(./../images/timeline-area-chart.svg);
}
.icon.icon-regression {
    mask-image: url(./../images/regression-chart.svg);
}
.icon.icon-blog {
    mask-image: url(./../images/manually-entered-data.svg);
}
.icon.icon-profile {
    mask-image: url(./../images/user.svg);
}
.icon.icon-admin {
    mask-image: url(./../images/cog.svg);
}
.icon.icon-govcouncil {
    mask-image: url(./../images/third-party.svg);
}
.icon.icon-forum {
    mask-image: url(./../images/comment.svg);
}
.icon.icon-at {
    mask-image: url(./../images/at.svg);
}
.icon.icon-map {
    mask-image: url(./../images/map.svg);
}
.icon.icon-membersvcs {
    mask-image: url(./../images/calculator.svg);
}
.icon.icon-foundations {
    mask-image: url(./../images/cube.svg);
}
.icon.icon-endorsed {
    mask-image: url(./../images/endorsed.svg);
}
.icon.icon-database {
    mask-image: url(./../images/database.svg);
}
.icon.icon-model {
    mask-image: url(./../images/array-numeric.svg);
}
.icon.icon-calculator {
    mask-image: url(./../images/calculator.svg);
}
.icon.icon-warning {
    mask-image: url(./../images/warning-sign.svg);
}
.icon.icon-pulse {
    mask-image: url(./../images/pulse.svg);
}
.icon.icon-support {
    mask-image: url(./../images/phone.svg);
}
.icon.info {
    mask-image: url(./../images/info-sm.svg);
    background: $text;
}
.icon.icon-notifications {
    mask-image: url(./../images/notifications.svg);
}
.icon-map-marker {
    mask-image: url(./../images/map-marker.svg);
}
.icon.icon-twitter-x {
    mask-image: url(./../images/twitter-x.svg);
    background: #dddddd;
    mask-repeat: no-repeat;
}
.icon.icon-tick {
    mask-image: url(./../images/tick.svg);
    margin-right: 10px;
    position: relative;
    top:3px;
}

.icon.icon-document-open {
    mask-image: url(./../images/document-open.svg);
    background: #fff; 
}

.icon.icon-exclamation {
    mask-image: url(./../images/exclamation.svg);
    background: #fff; 
}

.icon-nav {
    display:inline-flex;
    height: 1rem;
    width: 1rem;
    margin-right: .75rem;
    background: $text;
}

.icon.icon-lp {
    mask-image: url(./../images/grouped-bar-chart.svg);
    background: #ffffff;
    mask-repeat: no-repeat;
    height: 50%;
}

.pi.selected {
    color: $primary-light;
}

.icon.selected {
    background: $primary-light;    
}

.icon.icon-trending-up {
    mask-image: url(./../images/layout-auto.svg);
    mask-repeat: no-repeat;
    width: 300px;
    height: 300px;
    background: $primary;
    position: relative;
    line-height: 300px;
    margin: 0 auto;
    
}
.pi.success {
    color: $success-color;
}
.pi-exclamation-triangle,
.pi.warning {
    color: $warning-color;
}
.pi.info {
    color: $info-color
}

.p-dropdown-clear-icon {
    margin-right: .5rem;
}

.substack-watermark {
    mask-image: url('./../images/substack_wordmark.png');
    width: 68px;
    height: 16px;
    // padding: 10rem 0 0 0;
}
.p-input-icon-left > i, 
.p-input-icon-right > i, 
.p-input-icon-left > svg, 
.p-input-icon-right > svg, 
.p-input-icon-left > 
.p-input-prefix, 
.p-input-icon-right > 
.p-input-suffix {
    position: absolute;
    top: 50%;
}
