 .p-datepicker-header {
     background: $background !important;
     border-bottom: $background !important;
     .p-link:focus {
         box-shadow: none;
     }
 }
 .p-datepicker:not(.p-datepicker-inline) {
     background: $background !important;
     border: $border;
     box-shadow: $border;
 }
 .p-datepicker .p-datepicker-header{
    border-bottom: 1px solid $border !important;
    button {
        border: 1px solid $border !important;
    }
 }
 .p-datepicker .p-datepicker-buttonbar {
    border-top: 1px solid $border;
 }

 .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px $border;
}

.p-datepicker-today span {
    color: $primary-light !important;
    background: #3f3d5a !important;
}

.p-datepicker-trigger {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}