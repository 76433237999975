
.p-toolbar,
.p-menu,
.p-menu-overlay,
.p-card,
.p-tabview .p-tabview-panels,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.p-splitter,
.p-splitter-gutter-handle,
.p-splitter-gutter-resizing,
.p-selectbutton .p-button
, .p-dialog .p-dialog-header
, .p-dialog .p-dialog-footer
, .p-dialog .p-dialog-content
, .p-tooltip .p-tooltip-text
, .p-tabview .p-tabview-panels
, .p-tabview .p-tabview-nav li .p-tabview-nav-link
, .p-tabview .p-tabview-nav
//, .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link
//, .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link
, .p-dataview .p-dataview-content
, .p-menu.p-menu-overlay
, .p-paginator
, .p-editor-container .p-editor-toolbar
, .p-togglebutton.p-button
, .p-contextmen
, .p-dropdown
, .p-datepicker:not(.p-datepicker-inline)
, .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header
, .p-datepicker .p-datepicker-header
, .p-editor-container .p-editor-content .ql-editor
, .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link
, .p-datatable .p-datatable-footer
, .p-dropdown-panel
, .p-checkbox .p-checkbox-box
, .p-splitter .p-splitter-gutter .p-splitter-gutter-handle
, .p-password-panel
, .p-sidebar
, .p-datatable .p-datatable-header
, .p-slider .p-slider-range
, .p-slider .p-slider-handle
, .p-fieldset {
    color: unset;
    background: unset;
    //background-color: unset !important;
    //border-color: unset !important;
    border:unset;
    &:focus {
        box-shadow: none;
    }
}

.p-inputtext
, .p-inputtext:enabled:hover
, input
{
    border:unset;
    &:focus {
        box-shadow: none;
    }
    background: unset;
}
.p-inputtext:enabled:hover {
    border-color: unset;
}

.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > 
.p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > 
.p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > 
.p-datatable-tfoot, 
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr:not(.p-row-odd),
.p-datatable .p-sortable-column:focus,
.p-datatable .p-datatable-tbody > tr > td {
    background-color: unset !important;
    box-shadow: none !important;
    border: 0px solid $border;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler, 
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init, 
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save, 
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    width: 1rem !important;
    height: 1rem !important;
    background: unset !important;
    box-shadow: none  !important;
}

.p-carousel .p-carousel-content .p-carousel-prev:focus, .p-carousel .p-carousel-content .p-carousel-next:focus {
    box-shadow:none !important;
}
.p-button.p-button-success:enabled:focus, .p-button.p-button-success:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-success:enabled:focus, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):focus,
.p-button.p-button-warning:enabled:focus, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-warning:enabled:focus, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 .05rem $button-border !important;
}
.p-overlaypanel {
    background: unset !important;
    color: unset !important;
    border: unset !important;
    
}
.p-overlaypanel:before {
    border-color: transparent !important;
    border-bottom-color: transparent !important;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
    width: unset !important;
    height: unset !important;
}