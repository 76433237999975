@import './../styles/scss/_variables.scss';

.p-menu {
    border:0 !important;
    .p-menuitem-link{
        &:focus {
            box-shadow:none !important;
        }
    }
}

.p-menu-overlay {
    background: $background !important;
}

.sidebar {
    box-shadow: inset -0.275rem 0 0 -3.5px $background-border;
    max-width:calc(12% - 4px);

    .menu-spacer {
        margin-top: 3rem;
    }
    flex-basis:calc(12% - 4px);
    background:$sidebar;

    .toggle {
        bottom: .25rem;
        position:absolute;
        border: 0;
        display: flex;
        justify-content: center;
        .expanded:hover {
            color: #636363 !important;
        }
        .p-button-icon {
            font-size: 1.5rem;
            color: #636363;
            &:hover {
                color: #636363;
            }
        }
    }

    .nav-menu {
        ul{
            li:last-child {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.sidebar-left-true {
    display: none;
}

.nav-menu .nav-item {
    padding: 1 0;
    border-bottom: .5px solid $menu-item-border;
    margin: 0 1rem;
}

.nav-menu {
    width:100%;
    .nav-item {
        padding: 0;
        border-bottom: 1px solid $nav-item-border;
        margin: 0 1rem;
        a {
            white-space: nowrap;
        }
        .nav-item-link {
            display: flex;
            align-items: center;
            height: 3.25rem;
            .nav-text{
                font-size:1rem;
            }
            .nav-text.selected {
                color: $primary-light;
            }
        }
        a.selected {
            span {
                color: $primary-light;
            }
        }
        a:not(a.selected) {
            color: $text;
        }
    }
    .p-submenu-list {
        .nav-item { 
            border-bottom:0 !important;
            left: .5rem;
            position: relative;
        }
        
    }
    .nav-item-link.selected,
    .nav-item:hover {
        a span{
            color:$primary-light;
        }
        .icon-nav{
            background: $primary-light;        
        }
    }
    .p-menuitem-link:hover * {
        color: $primary-light !important;
    }
}

.sidebar.collapsed {
    flex-basis:unset !important;
    flex-grow: .333;
    .nav-item {
       justify-content:center;
       margin: 0 .25rem !important;
    }
    .icon-nav {
        margin-right:0;
    }
    .p-submenu-header {
        display: none;
    }
    .nav-sub-item {
        position: inherit;
    }
}

.p-sidebar {
    background: $sidebar;
    .p-sidebar-content {
        padding: 1rem;
    }
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover, 
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    // color: transparent !important;
    border:0 !important;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus, 
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    box-shadow: none;
}

.p-menu .p-submenu-header {
    background: transparent;
}
.nav-sub-item {
    position: relative;
    left:.75rem;
}