.p-splitter {    
    .p-splitter-panel-nested {
        display: inline-block;
    }
    
    .p-splitter-gutter:not(.messages-splitter .p-splitter-gutter) {
        height: 0 !important;
        width: 0 !important;
    }
    
    .p-splitter-panel.content {
        background: $background-dark;
        .p-scrollpanel-content {
            width: 100%;
        }
    }
}

