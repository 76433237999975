.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    box-shadow: inset 0 0 0 1px $border !important;
    background: transparent;
    color: $text;   
}

.p-paginator-bottom {
    background: #22242a !important;
    border: 1px solid $border !important;
    background: $table-head !important;
    // position: fixed;
    // bottom: .25rem;
    // width: 87%;
    // margin-top: 1rem;
}