@import './_variables';
@import "./_override";
@import "./_main";
@import "./_input";
@import "./_tabview";
@import "./_datatable";
@import "./_dialog";
@import "./_dropdown";
@import "./_button";
@import "./calendar";
@import "./_editor";
@import "./_icon";
@import "./_toast";
@import "./_tooltip";
@import "./_paginator";
@import "./_splitter";
@import "./_animation";
@import "./_screen";
@import "./_accordion";
@import "./_overlay";
@import "./_card";
@import "./_badge";
@import "./_toolbar";
@import "./_scrollpanel";
@import "./_checkbox";


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}