.p-overlaypanel {
    background: unset;
    color: unset;
    border: unset;
}
.p-overlaypanel-content {
    background: $overlay;
    color: $text;
    border: unset;
    border-radius: $border-radius !important;
    padding:.75rem;
    textarea {
        background: $input-background !important;
        &:hover {
            background: $input-background !important;
        }
        &:focus {
            background: $input-background !important;
        }
    }
}

.p-overlaypanel:after {
    border-color: transparent;
    border-bottom-color: $overlay;
    margin-left: .75rem;
}
.p-overlaypanel:before {
    border: transparent !important;
    border-color: transparent !important;
    border-bottom-color: $overlay-border !important;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: $overlay;
}
.p-overlaypanel:after {
    border-width: 8px;
    /* margin-left: -8px; */
}