.ms-modal__figure.ms-modal__figure--mobile + div {
    color: $text !important;
}

@media screen and (min-width: 100px) and (max-width: 374.99px){
    $color: darkgreen; //#darkgreen;
    $fontsize:2.55vw;
    $font-size: $fontsize !important;
    $scale_1: calc(#{$fontsize} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: 1px;
    $counter-margin: 2rem;
    $knob-text-size: calc(#{$fontsize} + 1.5rem);
    $counter-text-size: 3.5rem;
    $arrow-adjust: -0.45rem;
    $map-width: 100%;
    
    .bi {
        .report {
            iframe {
                width: 100%;
                height: 55.5rem;
            }
        }
    }

    header {        
        .app-name {
            padding: .25rem 0 0 1rem;
            .app-name-0 {
                font-size:2rem !important;
            }
        } 
    }

    // .powered-by {
    //     width: 100% !important;
    //     border: 0px solid red !important;
    //     position: relative !important;
    //     left: -3rem !important;
    //     img {
    //         width: 100% !important;
    //     }
    // }

    .auth button.create-account {
        position: relative;
        left: -5rem;
    }
    
    .h-logo div { 
        margin-top: .1rem !important;
    }

    .p-scrollpanel-content + .p-scrollpanel-bar-y {
        display: none;
    }

    .dashboard {
        padding-bottom: 5rem !important;
    }
    
    #embed-container {
        height: 30rem !important;
        z-index: 9999 !important;
    }

    .p-splitter-panel.content {
        overflow-y: hidden;
    }

    :not(.app-name-1):not(.pi):not(.p-knob-text):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.subleader>div):not(.subleader span):not(.my-dashboard)
    {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }
    .default {
        .p-galleria-content {
            background-size: 260% 100% !important;
            background-position-x: 35% !important;
            background-position-y: 0% !important;
            height: 100% !important;
        }
    }
    
    .default .p-galleria-item-container .p-galleria-item {
        .leader {
            font-size: 2.25rem !important;
        }
        .subleader {
            max-width: 75%;
            font-size: calc(2.25rem * .666) !important;
            >div {
                font-size: 1.1rem !important;
                max-width: 95%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.0rem !important;
            }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    div.dashboard {
        .grid {
            .panel {
                .title {
                    white-space: normal;
                }
                .p-knob.tps {
                    svg {
                        width: 10rem !important;
                        .p-knob-text {
                            font-size: $knob-text-size !important;
                        }
                    }
                }
                
            }
        }
    }

    .blog {
        .p-dataview {
            .p-dataview-content {
                height: calc(79vh) !important;
                overflow-y: auto;
            }
        }
        .col-3 {
            width: 100% !important;
            height: 33% !important;
        }
        .col-3 .preview {
            height: 90% !important;
            max-height: 90% !important;
            margin: 0 0.25rem 0.75rem !important;
            
            .title span {
                font-size: 1rem !important;
            }
        }
        .p-paginator {
            bottom: 0;
            position: fixed;
            width: 100%;
        }
    }

    .p-dialog.reader {
        width: 80% !important;
        iframe {
            width: 55%;
            height: 5rem;
        }
    }

    #AccountGroups {
        .p-inputtext.search {
            width: 80%;
        }        
    }

    .p-datatable .p-datatable-wrapper {
        overflow-x: auto !important;
    }

    .p-tabview.outer .p-tabview-panels {
        padding: 0;
    }
    .p-tabview-panel {
        overflow:auto;
    }

    .p-splitter-panel.content {
        overflow-y: hidden;
        .p-scrollpanel-content {
            width: 100%;
        }
    }

    iframe[title='reddit_embed'] {
        height: 16rem;
    }

    .pi {
        font-size: $font-size;
    }

    .sidebar{
        box-shadow: inset -0.275rem 0 0 -3.5px $background-border;
    }

    .sidebar, .sidebar.collapsed, .sidebar.expanded {
        .icon-nav {
            margin: 0 .25rem 0 .5rem !important;
        }
        .nav-item {
            justify-content: center;
        }
        .nav-text, .p-submenu-header {
            display:none !important;
        }
        .nav-sub-item {
            position: inherit;
        }
        
        ul li > .toggle {
            display:none !important;
        }

        flex-basis: calc(.5% - 4px) !important;
    }

    .p-selectbutton:not(.p-button-icon-only) .p-button {
        padding: 0.5rem .75rem;
    }

    .p-splitter-panel.content {
        width: calc(100% - 23px) !important;
    }

    header {
        margin: 0 !important;
        
        align-content: center !important;
        * {
            display: block;
        }
         .p-toolbar {
            top: .25rem;
            position:absolute;
            padding: 0 0 0 0;
            margin: 0 0 2rem 0 !important;
            .app-name-0 {
                font-size: 1.75rem !important;
            }
         }
    }

    .progress .vis {
        top: 2.5rem;
    }

    .p-splitter-panel.content {
        overflow-y: hidden;        
    }

    .dashboard  {
        .grid {
            width: 102%;
        }
        padding-bottom: 0rem;
    }

    .dashboard .grid .title {
        white-space: break-spaces;
    }

    .dashboard .grid .panel {
        width: 100% !important;
        padding-left:.5rem;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 100% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom: 7.75rem !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 95.25% !important;
    }

    .dashboard .p-toolbar {
        display: flex;
        justify-content: space-between;
        padding-right:0;        
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-dialog:not(.inline-dialog) {
        border: .5px solid $border !important;
                
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }
   
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $input-border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    } 

    .matrix {
        height: 100%;
        word-break: break-all;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.25rem;
                    //width: 10rem;
                }               
            }

            .row-0, td:first-child {
                line-height:1.5rem;
            }

            tr:first-of-type > td:last-of-type {
                width:3rem;
            }
        }
    }

    .services {
        width: 100%;
        .section{
            max-width: 100% !important;
            .text {
                margin: 0.5rem 1rem !important;
            }
            .search {
                width: 75%;
            }
            button {
                white-space: nowrap;
            }
            .p-tabview-nav{
                 overflow-x: auto !important;
                 overflow-y: hidden;
            }
        }
        
    }
    #accountDialog {
        width: 80% !important;
    }

    .panel {
        .card {
            border-radius: $borderRadius;
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
    }
    
    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }

    .ack-dlg {
        width: 50rem !important;
        position: absolute;
        bottom: 2.5px;
        float: right;
        .p-dialog-header {
            padding: 1rem;
        }
        .p-dialog-content {
            padding: 1.5rem;
            .consent{
                padding-top: 0 !important;
                line-height: 1.5rem;
                font-size:1.15rem;
            }
        }
    }

    .p-dataview-header {
        display:none;
    }
    figure.ms-modal__figure--mobile {
        display:none;
    }
}

@media screen and (min-width: 375px) and (max-width: 389.99px){
    $color: white; //#6fc0b9;
    $fontsize:1.95vw;
    $font-size: $fontsize !important;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 2rem;
    $knob-text-size: 3rem;
    $counter-text-size: 2.4rem;
    $arrow-adjust: -0.45rem;
    $map-width: 100%;
    
    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 60.5rem;
            }
        }
    }

    header {
        .app-name-0 {
            font-size:2rem !important;
        }
    }

    // .powered-by {
    //     width: 100% !important;
    //     border: 0px solid red !important;
    //     left: -3rem !important;
    //     img {
    //         width: 100% !important;
    //     }
    // }

    .auth button.login {
        position: relative;
        left: 20rem;
    }

    .p-scrollpanel-content + .p-scrollpanel-bar-y {
        display: none;
    }

    #embed-container {
        height: 20rem !important;
    }

    :not(.app-name-1):not(.pi):not(.p-knob-text):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.subleader span):not(.subleader>div):not(.my-dashboard)  {
        font-size: $font-size;
    }

    .default {
        .p-galleria-indicators {
            justify-content: left !important;
            margin-left: 1.5rem !important;
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        margin: 0 2rem !important;
        .leader {
            font-size: 2.25rem !important;
        }
        .subleader {
            max-width: 75%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 85%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.0rem !important;
            }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        margin-left: 2rem !important;
        .subleader {
            max-width: 90%;
        }
    }

    .default {
        .p-galleria-content {
            background-size: 200% 100% !important;
            background-position-x: 40% !important;
            background-position-y: 0% !important;
            height: 100% !important;
        }
    }

    .blog {
        .p-dataview {
            .p-dataview-content {
                height: calc(82.5vh) !important;
                overflow-y: auto;
            }
        }
        .col-3 {
            width: 100% !important;
            height: 22.5% !important;
        }

        .p-paginator {
            width: 100%;
        }

        .col-3 .preview {
            height: 95% !important;
            max-height: 95% !important;
            margin: 0 0.25rem 0.75rem !important;
            
            .title span {
                font-size: 1rem !important;
            }
        }
    }

    .p-dialog.reader {
        width: 80% !important;
        iframe {
            width: 55%;
            height: 5rem;
        }
    }

    #AccountGroups {
        .p-inputtext.search {
            width: 80%;
        }        
    }

    .p-datatable .p-datatable-wrapper {
        overflow-x: auto !important;
    }

    div.dashboard {
        .grid {
            .panel {
                .title {
                    white-space: normal;
                }
                .p-knob.tps {
                    svg {
                        width: 10rem !important;
                        .p-knob-text {
                            font-size: $knob-text-size !important;
                        }
                    }
                }
                
            }
        }
    }

    .pi {
        font-size: $font-size;
    }

    .sidebar{
        box-shadow: inset -0.275rem 0 0 -3.5px $background-border;
    }

    .sidebar, .sidebar.collapsed, .sidebar.expanded {
        .icon-nav {
            margin: 0 .25rem 0 .5rem !important;

        }
        .nav-item {
            justify-content: center;
        }
        .nav-text, .p-submenu-header {
            display:none !important;
        }
        .nav-sub-item {
            position: inherit;
        }
        ul li > .toggle {
            display:none !important;
        }

        flex-basis: calc(.5% - 4px) !important;
    }

    .p-selectbutton:not(.p-button-icon-only) .p-button {
        padding: 0.5rem .75rem;
    }

    .p-splitter-panel.content {
        width: calc(100% - 23px) !important;
    }

    header {
         font-size: $font-size;
    }

    iframe[title='reddit_embed'] {
        margin: 0 0 1rem 0 !important;
        height: 20rem;
    }

    .p-splitter-panel.content {
        overflow-y: hidden;
        .p-scrollpanel-content {
            width: 100%;
            overflow-y: auto  !important;
        }
    }

    .dashboard  {
        .grid {
            width: 106%;
        }
        padding-bottom: 5rem;
    }

    .dashboard .grid .title {
        white-space: break-spaces;
    }

    .dashboard .grid .panel {
        width: 48% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 97.25% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom: 7.75rem !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 92.5% !important;
    }

    .dashboard .p-toolbar {
        display: flex;
        justify-content: space-between;
        padding-right:0;        
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-dialog:not(.inline-dialog) {
        border: .5px solid $border !important;
                
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }
   
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $input-border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .geo-ortho-map {
        .p-splitter {
            flex-direction: column;
            .p-splitter-panel {
                .world-map {
                    display:none;
                }
            }
        }
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
                td {
                    padding: 4rem .25rem !important;
                    
                }
            }

            .row-0, td:first-child {
                line-height:1.5rem;
            }

            td:first-child {
                line-height:1.5rem;
            }

            tr.table-row:last-of-type {
                height:4rem;
                font-size:$scale_1;
            }

            tr:first-of-type > td:last-of-type {
                width:5rem;
            }

            tr:last-of-type > td:last-of-type {
                font-size: 1.25em;
            }
        }
    }

    .services {
        width: 100%;
        .section{
            max-width: 100% !important;
            .text {
                margin: 0.5rem 1rem !important;
            }
            .search {
                width: 75%;
            }
            button {
                white-space: nowrap;
            }
            .p-tabview-nav{
                 overflow-x: auto !important;
                 overflow-y: hidden;
            }
        }
        
    }
    #accountDialog {
        width: 80% !important;
    }
    .panel {
        .card {
            border-radius: $borderRadius;
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    
    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }

    .ack-dlg {
        width: 50rem !important;
        position: absolute;
        bottom: 2.5px;
        float: right;
        .p-dialog-header {
            padding: 1rem;
        }
        .p-dialog-content {
            padding: 1.5rem;
            .consent{
                padding-top: 0 !important;
                line-height: 1.5rem;
                font-size:1.15rem;
            }
        }
    }

    .p-dataview-header {
        display:none;
    }
    figure.ms-modal__figure--mobile {
        display:none;
    }
}

@media screen and (min-width: 390px) and (max-width: 409.99px){
    $color: lightblue; //#6fc0b9;
    $fontsize:1.95vw;
    $font-size: $fontsize !important;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 2rem;
    $knob-text-size: 2.75rem;
    $counter-text-size: 2.4rem;
    $arrow-adjust: -0.45rem;
    $map-width: 100%;
    
    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 60.5rem;
            }
        }
    }

    header {
        .app-name-0 {
            font-size:2rem !important;
        }
    }


    // .powered-by {
    //     width: 100% !important;
    //     border: 0px solid red !important;
    //     position: relative !important;
    //     left: -3rem !important;
    //     img {
    //         width: 100% !important;
    //     }
    // }

    .p-scrollpanel-content + .p-scrollpanel-bar-y {
        display: none;
    }

    #embed-container {
        height: 15rem !important;
    }

    :not(.app-name-1):not(.pi):not(.p-knob-text):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.subleader span):not(.subleader>div):not(.my-dashboard)  {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        margin: 0 2rem !important;
        .leader {
            font-size: 2.5rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.5 * .666) !important;
            >div {
                font-size: calc(2.5 * .5) !important;
                max-width: 75%;
            }
            span {
                font-size: calc(2.5 * .5) !important;
            }
            .callout {
                font-size: calc(2.5 * .5) !important;
            }
        }
        .leaderlist {
            font-size: calc(2.5 * .5) !important;
            li {
                font-size: calc(2.5 * .5) !important;
            }
        }
    }

    .default .p-galleria-content {
        .p-galleria-content {
            background-size: 100% 50% !important;
            background-position-x: 25% !important;
            background-position-y: 1% !important;
            height: 100% !important;
        }
    }

    .blog {
        .p-dataview {
            .p-dataview-content {
                height: calc(82.5vh) !important;
                overflow-y: auto;
            }
        }
        .col-3 {
            width: 100% !important;
            height: 22.5% !important;
        }

        .p-paginator {
            width: 100%;
        }

        .col-3 .preview {
            height: 95% !important;
            max-height: 95% !important;
            margin: 0 0.25rem 0.75rem !important;
            
            .title span {
                font-size: 1rem !important;
            }
        }
    }

    .p-dialog.reader {
        width: 80% !important;
        iframe {
            width: 55%;
            height: 5rem;
        }
    }

    #AccountGroups {
        .p-inputtext.search {
            width: 80%;
        }
        
    }

    .p-datatable .p-datatable-wrapper {
        overflow-x: auto !important;
    }

    div.dashboard {
        .grid {
            .panel {
                .title {
                    white-space: normal;
                }
                .p-knob.tps {
                    svg {
                        width: 10rem !important;
                        .p-knob-text {
                            font-size: $knob-text-size !important;
                        }
                    }
                }
                
            }
        }
    }

    .pi {
        font-size: $font-size;
    }

    .sidebar{
        box-shadow: inset -0.275rem 0 0 -3.5px $background-border;
    }

    .sidebar, .sidebar.collapsed, .sidebar.expanded {
        .icon-nav {
            margin: 0 .25rem 0 .5rem !important;

        }
        .nav-item {
            justify-content: center;
        }
        .nav-text, .p-submenu-header {
            display:none !important;
        }
        .nav-sub-item {
            position: inherit;
        }
        ul li > .toggle {
            display:none !important;
        }

        flex-basis: calc(.5% - 4px) !important;
    }

    .p-selectbutton:not(.p-button-icon-only) .p-button {
        padding: 0.5rem .75rem;
    }

    .p-splitter-panel.content {
        width: calc(100% - 23px) !important;
    }

    header {
         font-size: $font-size;
         .p-toolbar {
            display: flex !important;
            padding-top: .5rem;
            justify-content: space-between !important;
            .p-toolbar-group-start {
                justify-content: start;
            }
         }
    }

    iframe[title='reddit_embed'] {
        margin: 0 0 1rem 0 !important;
        height: 20rem;
    }

    .p-splitter-panel.content {
        overflow-y: hidden;
        .p-scrollpanel-content {
            width: 101.5%;
        }
    }

    .dashboard  {
        .grid {
            width: 106%;
        }
        padding-bottom: 5rem;
    }

    .dashboard .grid .title {
        white-space: break-spaces;
    }

    .dashboard .grid .panel {
        width: 46.75% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 95% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom: 7.75rem !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 92.5% !important;
    }

    .dashboard .p-toolbar {
        display: flex;
        justify-content: space-between;
        padding-right:0;        
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-dialog:not(.inline-dialog) {
        border: .5px solid $border !important;
                
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }
   
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $input-border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .geo-ortho-map {
        .p-splitter {
            flex-direction: column;
            .p-splitter-panel {
                .world-map {
                    display:none;
                }
            }
        }
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
                td {
                    padding: 4rem .25rem !important;
                    
                }
            }

            .row-0, td:first-child {
                line-height:1.5rem;
            }

            td:first-child {
                line-height:1.5rem;
            }

            tr.table-row:last-of-type {
                height:4rem;
                font-size:$scale_1;
            }

            tr:first-of-type > td:last-of-type {
                width:5rem;
            }

            tr:last-of-type > td:last-of-type {
                font-size: 1.25em;
            }
        }
    }

    .services {
        width: 100%;
        .section{
            max-width: 100% !important;
            .text {
                margin: 0.5rem 1rem !important;
            }
            .search {
                width: 75%;
            }
            button {
                white-space: nowrap;
            }
            .p-tabview-nav{
                 overflow-x: auto !important;
                 overflow-y: hidden;
            }
        }
        
    }
    #accountDialog {
        width: 80% !important;
    }
    .panel {
        .card {
            border-radius: $borderRadius;
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    
    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }

    .ack-dlg {
        width: 50rem !important;
        position: absolute;
        bottom: 2.5px;
        float: right;
        .p-dialog-header {
            padding: 1rem;
        }
        .p-dialog-content {
            padding: 1.5rem;
            .consent{
                padding-top: 0 !important;
                line-height: 1.5rem;
                font-size:1.15rem;
            }
        }
    }

    .p-dataview-header {
        display:none;
    }
    figure.ms-modal__figure--mobile {
        display:none;
    }
}

@media screen and (min-width: 410px) and (max-width: 494.99px){
    $color: blue; //#6fc0b9;
    $fontsize:2.333vw;
    $font-size: $fontsize !important;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 2rem;
    $knob-text-size: 2.25em;
    $counter-text-size: 4rem;
    $arrow-adjust: -0.45rem;
    $map-width: 100%;
    
    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 60.5rem;
            }
        }
    }

    

    .app-name {
        padding-left: 0rem;
        .h-logo div {
            position: relative !important;
            top: .1rem !important;
        }
    }

    // .powered-by {
    //     width: 100% !important;
    //     border: 0px solid red !important;
    //     position: relative !important;
    //     left: -3rem !important;
    //     img {
    //         width: 120% !important;
    //     }
    // }

    .p-scrollpanel-content + .p-scrollpanel-bar-y {
        display: none;
    }

    .dashboard {
        padding-bottom: 5rem;
    }
    #embed-container {
        height: 50rem !important;
    }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.subleader span):not(.my-dashboard):not(.indicator-text):not(.subleader>div)  {
        font-size: $font-size;
    }

    .default {
        .p-galleria-indicators {
            justify-content: center !important;
            margin-left: -1rem !important;
            .indicator-text {
                font-size: .9rem !important;
            }            
        }

        .p-galleria-content {
            background-size: 150% 70% !important;
            background-position-x: 25% !important;
            background-position-y: 1% !important;
            height: 80% !important;
        }
    }
    
    .default .p-galleria-item-container .p-galleria-item {
        margin: 0 4rem !important;
        .leader {
            font-size: 2.25rem !important;
        }
        .subleader {
            max-width: 75%;
            font-size: calc(2.25rem * .666) !important;
            >div {
                font-size: 1.1rem !important;
                max-width: 85%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.0rem !important;
            }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .default {
        .p-galleria-indicators {
            justify-content: left !important;
            margin-left: .5rem !important;
        }
        .p-galleria-content {
            background-size: 260% 100% !important;
            background-position-x: 35% !important;
            background-position-y: 0% !important;
            height: 100% !important;
        }
    }

    .p-scrollpanel{
        overflow-y: hidden !important;
    }

    .blog {
        .p-dataview {
            .p-dataview-content {
                height: calc(78vh) !important;
                overflow-y: auto;
            }
        }
        .col-3 {
            width: 100% !important;
            height: 22.5% !important;
        }
        
        .p-paginator {
            width: 100%;
            position: fixed;
            bottom: 0;

        }
        
        .col-3 .preview {
            height: 97% !important;
            max-height: 97% !important;
            margin: 0 0.25rem 0.75rem !important;
            
            .title span {
                font-size: 1rem !important;
            }
        }
    }

    div.dashboard {
        .grid {
            .panel {
                .title {
                    white-space: normal;
                }
                .p-knob.tps {
                    svg {
                        width: 12rem !important;
                        .p-knob-text {
                            font-size: $knob-text-size !important;
                        }
                    }
                }
                
            }
        }
    }

    .p-dialog.reader {
        width: 80% !important;
        iframe {
            width: 55%;
            height: 5rem;
        }
    }

    #AccountGroups {
        .p-inputtext.search {
            width: 80%;
        }
        
    }

    .p-datatable .p-datatable-wrapper {
        overflow-x: auto !important;
    }

    .p-tabview.outer .p-tabview-panels {
        padding: 0;
    }
    .p-tabview-panel {
        overflow:auto;
    }

    .pi {
        font-size: $font-size;
    }

    .sidebar{
        box-shadow: inset -0.275rem 0 0 -3.5px $background-border;
    }

    .sidebar, .sidebar.collapsed, .sidebar.expanded {
        .icon-nav {
            margin: 0 .25rem 0 .5rem !important;

        }
        .nav-item {
            justify-content: center;
        }
        .nav-text, .p-submenu-header {
            display:none !important;
        }
        .nav-sub-item {
            position: inherit;
        }
        ul li > .toggle {
            display:none !important;
        }

        flex-basis: calc(.5% - 4px) !important;
    }

    .p-selectbutton:not(.p-button-icon-only) .p-button {
        padding: 0.5rem .75rem;
    }

    .p-splitter-panel.content {
        width: calc(100% - 23px) !important;
    }

    header {
        align-items: center;
        display: flex;
        font-size: $font-size;
        .p-toolbar {
            display: flex !important;
            padding-top: .25rem;
            justify-content: space-between !important;
            .p-toolbar-group-start {
                justify-content: start;
            }
         }
    }

    iframe[title='reddit_embed'] {
        margin: 0 0 0rem 0 !important;
        height: 20rem;
    }

    .p-splitter-panel.content {
        overflow-y: hidden;
        .p-scrollpanel-content {
            width: 100%;
        }
    }

    .dashboard  {
        .grid {
            width: 106%;
        }
        //padding-bottom: 5rem;
    }

    .dashboard .grid .title {
        white-space: break-spaces;
    }

    .dashboard .grid .panel {
        width: 97.0% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 97.0% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom: 7.75rem !important;
    }

    .dashboard .p-toolbar {
        display: flex;
        justify-content: space-between;
        padding-right:0;        
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-dialog:not(.inline-dialog) {
        border: .5px solid $border !important;
                
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }
   
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $input-border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }    

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
                td {
                    padding: 4rem .25rem !important;
                    
                }
            }

            .row-0, td:first-child {
                line-height:1.5rem;
            }

            td:first-child {
                line-height:1.5rem;
            }

            tr.table-row:last-of-type {
                height:4rem;
                font-size:$scale_1;
            }

            tr:first-of-type > td:last-of-type {
                width:5rem;
            }

            tr:last-of-type > td:last-of-type {
                font-size: 1.25em;
            }
        }
    }

    .services {
        width: 100%;
        .section{
            max-width: 100% !important;
            .text {
                margin: 0.5rem 1rem !important;
            }
            .search {
                width: 75%;
            }
            button {
                white-space: nowrap;
            }
            .p-tabview-nav{
                 overflow-x: auto !important;
                 overflow-y: hidden;
            }
        }
        
    }
    #accountDialog {
        width: 80% !important;
    }
    .panel {
        .card {
            border-radius: $borderRadius;
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    
    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }

    .ack-dlg {
        width: 50rem !important;
        position: absolute;
        bottom: 2.5px;
        float: right;
        .p-dialog-header {
            padding: 1rem;
        }
        .p-dialog-content {
            padding: 1.5rem;
            .consent{
                padding-top: 0 !important;
                line-height: 1.5rem;
                font-size:1.15rem;
            }
        }
    }

    .p-dataview-header {
        display:none;
    }
    figure.ms-modal__figure--mobile {
        display:none;
    }
}

@media screen and (min-width: 495px) and (max-width: 767.99px){
    $color: greenyellow; //#6fc0b9;
    $fontsize:2vw;
    $font-size: $fontsize !important;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: 1px;
    $counter-margin: 2rem;
    $knob-text-size: 1.5rem;
    $counter-text-size: 2.25rem;
    $arrow-adjust: -0.45rem;
    $map-width: 100%;
    
    .bi {
        .report {
            iframe {
                width: 100%;
                height: 55.5rem;
            }
        }
    }

    header {
        // height: 8rem !important;
        .h-logo div {
            padding-top: 0rem;
        }
    }

    // .powered-by {
    //     width: 100% !important;
    //     border: 0px solid red !important;
    //     position: relative !important;
    //     margin-left: 4rem !important;
    //     img {
    //         width: 80% !important;
    //     }
    // }

    .h-logo>div {
        padding-top:.1rem !important;
    }

    .dev button.create-account:not(.button-as-link) {
        background-color: $color !important;
    }

    .p-scrollpanel-content + .p-scrollpanel-bar-y {
        display: none;
    }

    .dashboard {
        padding-bottom: 5rem;
    }

    #embed-container {
        height: 20rem !important;
        z-index: 9999 !important;
    }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.subleader span):not(.my-dashboard)  {
        font-size: $font-size;
    }

    .default {
        .p-galleria-indicators {
            display: flex !important;
            justify-content: left !important;
            margin-left: 5rem;
        }
    }
    

    .default .p-galleria-item-container .p-galleria-item {
        margin: 0 4rem !important;
        .leader {
            font-size: 2.5rem !important;
        }
        .subleader {
            max-width: 95%;
            font-size: calc(2.5rem * .666) !important;
            >div {
                font-size: 1.1rem !important;
                max-width: 85%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.0rem !important;
            }
        }
        .leaderlist {
            font-size: calc(2.5rem * .5) !important;
            li {
                font-size: calc(2.5rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .p-splitter-panel.content {
        overflow-y: hidden;
    }

    .blog {
        .p-dataview {
            .p-dataview-content {
                height: calc(78vh) !important;
                overflow-y: auto;
            }
        }
        .col-3 {
            width: 100% !important;
            height: 33% !important;
        }

        .col-3 .preview {
            height: 90% !important;
            max-height: 90% !important;
            margin: 0 0.25rem 0.75rem !important;
            
            .title span {
                font-size: 1rem !important;
            }
        }
        .p-paginator {
            width: 80%;
            position: fixed !important;
            bottom: 0 !important;
        }
    }

    div.dashboard {
        // iframe[title='reddit_embed'] {
        //     display: none !important;
        // }
        .grid {
            .panel {
                .title {
                    white-space: normal;
                }
                .p-knob.tps {
                    svg {
                        width: 12rem !important;
                        .p-knob-text {
                            font-size: $knob-text-size !important;
                        }
                    }
                }
                
            }
        }
    }

    .p-dialog.reader {
        width: 80% !important;
        iframe {
            width: 55%;
            height: 5rem;
        }
    }

    .p-splitter-panel.content .p-scrollpanel-content {
        width: 100%;
        overflow-y: auto !important;
    }

    iframe[title='reddit_embed'] {
        height: 17.0rem;
    }

    .pi {
        font-size: $font-size;
    }

    .sidebar{
        box-shadow: inset -0.275rem 0 0 -3.5px $background-border;
    }

    .sidebar, .sidebar.collapsed, .sidebar.expanded {
        .icon-nav {
            margin: 0 .25rem 0 .5rem !important;
        }
        .nav-item {
            justify-content: center;
        }
        .nav-text, .p-submenu-header {
            display:none !important;
        }
        .nav-sub-item {
            position: inherit;
        }
        
        ul li > .toggle {
            display:none !important;
        }

        flex-basis: calc(.5% - 4px) !important;
    }

    .p-selectbutton:not(.p-button-icon-only) .p-button {
        padding: 0.5rem .75rem;
    }

    .p-splitter-panel.content {
        width: calc(100% - 23px) !important;
    }

    header {
        padding: .25rem .5rem .25rem 1rem !important;
        align-content: center;
        .p-toolbar {
            display: flex !important;
            justify-content: space-between !important;
        .p-toolbar-group-start {
            justify-content: start;
        }
        }
    }

    .p-splitter-panel.content {
        overflow-x: hidden;
    }

    .dashboard  {
        .grid {
            width: 103%;
        }
        padding-bottom: 0rem;
    }

    .dashboard .grid .title {
        white-space: break-spaces;
    }

    .dashboard .grid .panel {
        width: 47% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 95.25% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom: 7.75rem !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 95.25% !important;
    }

    .dashboard .p-toolbar {
        display: flex;
        justify-content: space-between;
        padding-right:0;        
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-dialog:not(.inline-dialog) {
        border: .5px solid $border !important;
                
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }
   
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $input-border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }    

    .matrix {
        height: 100%;
        word-break: break-all;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.25rem;
                }                
            }

            .row-0, td:first-child {
                line-height:1.5rem;
            }

            tr:first-of-type > td:last-of-type {
                width:3rem;
            }
        }
    }

    .services {
        width: 100%;
        .section{
            max-width: 100% !important;
            .text {
                margin: 0.5rem 1rem !important;
            }
            .search {
                width: 75%;
            }
            button {
                white-space: nowrap;
            }
            .p-tabview-nav{
                 overflow-x: auto !important;
                 overflow-y: hidden;
            }
        }
        
    }
    #accountDialog {
        width: 80% !important;
    }
    .panel {
        .card {
            border-radius: $borderRadius;
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    
    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }

    .ack-dlg {
        width: 50rem !important;
        position: absolute;
        bottom: 2.5px;
        float: right;
        .p-dialog-header {
            padding: 1rem;
        }
        .p-dialog-content {
            padding: 1.5rem;
            .consent{
                padding-top: 0 !important;
                line-height: 1.5rem;
                font-size:1.15rem;
            }
        }
    }

    .p-dataview-header {
        display:none;
    }
    figure.ms-modal__figure--mobile {
        display:none;
    }
}

//-----------------------------------------------------------

@media screen and (min-width: 768px) and (max-width: 819.99px){
    $color: dodgerblue;
    $fontsize:2.0vw;
    $font-size: $fontsize !important;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5rem;
    $knob-text-size: 1.5em;
    $counter-text-size: calc(#{$font-size});
    $arrow-adjust: -0.45rem;
    $map-width: 100%;

    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 115%;
            }
        }
    }

    //  .powered-by {
    //     width: 50% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: 4rem !important;
    //     img {
    //         width: 100% !important;
    //     }
    // }

    .p-scrollpanel-content + .p-scrollpanel-bar-y {
        display: none;
    }

    .p-splitter-panel.content .p-scrollpanel-content {
        width: 100% //92.0;
    }

    #embed-container {
        height: 15rem !important;
    }

    .dev button.create-account:not(.button-as-link) {
        background-color: $color !important;
    }

    
    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.subleader span):not(.subleader>div):not(.my-dashboard)  {
        font-size: $font-size;
    }

    .default {
        .p-galleria-indicators {
            justify-content: center !important;
            margin-left: 1.5rem !important;
            .indicator-text {
                font-size: .9rem !important;
            }            
        }
    }
    
    .default .p-galleria-item-container .p-galleria-item {
        margin: 0 3rem !important;
        .leader {
            font-size: 2.25rem !important;
        }
        .subleader {
            max-width: 75%;
            font-size: calc(2.25rem * .666) !important;
            padding-bottom:0 !important;
            >div {
                font-size: 1.1rem !important;
                max-width: 85%;
                line-height: 1.5rem !important;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.0rem !important;
            }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size: 1.75rem !important;
        }
        .item {
            position: relative;
            top:-7.5%;
        }
    }

    .default .p-galleria-content {
        background-size: 150% 100% !important;
        background-position-x: 65% !important;
        background-position-y: 1% !important;
        height: 100% !important;
    }

    .p-overlaypanel:after {
        border-bottom-color: transparent !important;
    }

    .pi {
        font-size: $font-size;
    }

    .p-tabview-panels{
        overflow-y: hidden !important;
    }
    
    .blog {
        .p-dataview {
            .p-dataview-content {
                height: calc(75vh) !important;
                overflow-y: auto;
            }
        }
        .col-3 {
            width: 100% !important;
            height: 33% !important;
        }
        
        .p-paginator {
            width: 100%;
            position: fixed;
            bottom: 0;
        }
        
        .col-3 .preview {
            height: 97% !important;
            max-height: 97% !important;
            margin: 0 0.25rem 0.75rem !important;
            
            .title span {
                font-size: 1rem !important;
            }
        }
    }

    div.dashboard {
        .grid {
            .panel {
                .title {
                    white-space: normal;
                }
                .p-knob.tps {
                    svg {
                        width: 12rem !important;
                        .p-knob-text {
                            font-size: $knob-text-size !important;
                        }
                    }
                }
                
            }
        }
    }

    .sidebar {
        box-shadow: inset -0.275rem 0 0 -3.5px $background-border;
    }

    .sidebar, .sidebar.collapsed, .sidebar.expanded {
        .icon-nav {
            margin: 0 .25rem 0 .5rem !important;
        }
        .nav-item {
            justify-content: center;
        }
        .nav-text, .p-submenu-header {
            display:none !important;
        }
        .nav-sub-item {
            position: inherit;
        }
        
        ul li > .toggle {
            display:none !important;
        }

        flex-basis: calc(.5% - 4px) !important;
    }

    .dashboard .grid .panel {
        width: 48.4% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 96.8% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 98.1% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:7.25rem !important;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-scrollpanel-bar-y {
        width:.75rem;
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;       
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }

    .p-carousel-prev,.p-carousel-next {
        display:none;
    }
    
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $input-border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
                td {
                    padding: 4rem .25rem !important;
                    
                }
            }

            .row-0, td:first-child {
                line-height:1.5rem;
            }

            td:first-child {
                line-height:1.5rem;
            }

            tr.table-row:last-of-type {
                height:4rem;
                font-size:$scale_1;
            }

            tr:first-of-type > td:last-of-type {
                width:5rem;
            }

            tr:last-of-type > td:last-of-type {
                font-size: 1.25em;
            }
        }
    }  

    .panel {
        .card {
            border-radius: $borderRadius;
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }

    .ack-dlg {
        width: 50rem !important;
        position: absolute;
        bottom: 2.5px;
        float: right;
        .p-dialog-header {
            padding: 1rem;
        }
        .p-dialog-content {
            padding: 1.5rem;
            .consent{
                padding-top: 0 !important;
                line-height: 1.5rem;
                font-size:1.15rem;
            }
        }
    }
}

@media screen and (min-width: 820px) and (max-width: 959.99px){
    $color: red;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5rem;
    $knob-text-size: 3.0em;
    $counter-text-size: calc(#{$font-size} + 1.5vw);
    $arrow-adjust: -0.45rem;
    $map-width: 100%;

    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 115%;
            }
        }
    }

    // .powered-by {
    //     width: 15% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: -7.5rem !important;
    //     img {
    //         width: 150% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        .leader {
            font-size: 2.75rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: 1.75rem !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: 1.25rem !important;
            li {
                font-size: 1.25rem !important;
            }
        }
    }

    .p-overlaypanel:after {
        border-bottom-color: transparent !important;
    }

    .pi {
        font-size: $font-size;
    }

    .col-3 {
        .preview {
            height: 100% !important;
        }
    }

    .p-paginator {
        bottom: 0;
        position: fixed;
        width: 80%;
    }

    .sidebar {
        box-shadow: inset -0.5rem 0 0 -3.5px $background-border;
    }

    .dashboard .grid .panel {
        width: 24.3% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 49.2% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74.1% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:7.25rem !important;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-scrollpanel-bar-y {
        width:.75rem;
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;       
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }

    .p-carousel-prev,.p-carousel-next {
        display:none;
    }
    
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $input-border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
                td {
                    padding: 4rem .25rem !important;
                    
                }
            }

            .row-0, td:first-child {
                line-height:1.5rem;
            }

            td:first-child {
                line-height:1.5rem;
            }

            tr.table-row:last-of-type {
                height:4rem;
                font-size:$scale_1;
            }

            tr:first-of-type > td:last-of-type {
                width:5rem;
            }

            tr:last-of-type > td:last-of-type {
                font-size: 1.25em;
            }
        }
    }  

    .panel {
        .card {
            border-radius: $borderRadius;
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }

    .ack-dlg {
        width: 50rem !important;
        position: absolute;
        bottom: 2.5px;
        float: right;
        .p-dialog-header {
            padding: 1rem;
        }
        .p-dialog-content {
            padding: 1.5rem;
            .consent{
                padding-top: 0 !important;
                line-height: 1.5rem;
                font-size:1.15rem;
            }
        }
    }
}

@media screen and (min-width: 820px) and (max-width: 820px){
    $color: purple;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5rem;
    $knob-text-size: 3.0em;
    $counter-text-size: 2.25rem;
    $arrow-adjust: -0.45rem;
    $map-width: 100%;

    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 115%;
            }
        }
    }

    // .powered-by {
    //     width: 15% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: 5rem !important;
    //     img {
    //         width: 350% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.subleader span):not(.subleader>div):not(.my-dashboard) {
        font-size: $font-size;
    }
    
    .default .p-galleria-item-container .p-galleria-item {
        margin: 0 4rem !important;
        .leader {
            font-size: 2.5rem !important;
        }
        .subleader {
            max-width: 95%;
            font-size: calc(2.5rem * .666) !important;
            >div {
                font-size: calc(2.5rem * .5) !important;
                max-width: 85%;
                line-height: 2rem !important;
            }
             span {
                 font-size: calc(2.5rem * .5) !important;
            }
            .callout {
                font-size: calc(2.5rem * .5) !important;
            }
        }
        .leaderlist {
            font-size: calc(2.5rem * .5) !important;
            li {
                font-size: calc(2.5rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .default {
        .p-galleria-indicators {
            justify-content: left !important;
            margin-left: 5rem !important;
        }
    }
    .default .p-galleria-content {
        
        background-size: 70% 100% !important;
        background-position-x: -55% !important;
        background-position-y: 1% !important;
        height: 90% !important;
    }

    .p-overlaypanel:after {
        border-bottom-color: transparent !important;
    }

    .pi {
        font-size: $font-size;
    }

    //.blog {
        .col-3 {
            .preview {
                height: 100% !important;
            }
        }

        .p-paginator {
            bottom: 0;
            position: fixed;
            width: 80%;
        }
    //}

    .sidebar {
        box-shadow: inset -0.275rem 0 0 -3.5px $background-border;
    }

    .sidebar, .sidebar.collapsed, .sidebar.expanded {
        .icon-nav {
            margin: 0 .25rem 0 .5rem !important;
        }
        .nav-item {
            justify-content: center;
        }
        .nav-text, .p-submenu-header {
            display:none !important;
        }
        .nav-sub-item {
            position: inherit;
        }
        
        ul li > .toggle {
            display:none !important;
        }

        flex-basis: calc(.5% - 4px) !important;
    }

    .dashboard .grid .panel {
        width: 48.5% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 98.5% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74.1% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:7.25rem !important;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-scrollpanel-bar-y {
        width:.75rem;
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;       
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }

    .p-carousel-prev,.p-carousel-next {
        display:none;
    }
    
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $input-border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
                td {
                    padding: 4rem .25rem !important;
                    
                }
            }

            .row-0, td:first-child {
                line-height:1.5rem;
            }

            td:first-child {
                line-height:1.5rem;
            }

            tr.table-row:last-of-type {
                height:4rem;
                font-size:$scale_1;
            }

            tr:first-of-type > td:last-of-type {
                width:5rem;
            }

            tr:last-of-type > td:last-of-type {
                font-size: 1.25em;
            }
        }
    }  

    .panel {
        .card {
            border-radius: $borderRadius;
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }

    .ack-dlg {
        width: 50rem !important;
        position: absolute;
        bottom: 2.5px;
        float: right;
        .p-dialog-header {
            padding: 1rem;
        }
        .p-dialog-content {
            padding: 1.5rem;
            .consent{
                padding-top: 0 !important;
                line-height: 1.5rem;
                font-size:1.15rem;
            }
        }
    }
}

//-----------------------------------------------------------

@media screen and (min-width: 960px) and (max-width: 1023.99px){
    $color: lightseagreen;
    $base:4px;
    $font-size: 1.333vw !important;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5rem;
    $knob-text-size: 2.2em;
    $counter-text-size: calc(#{$base} + 1.5vw);
    $arrow-adjust: -0.45rem;
    $map-width: 100%;
    
    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 113.5%;
            }
        }
    }

    

    // .powered-by {
    //     width: 15% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: -10rem !important;
    //     img {
    //         width: 175% !important;
    //     }
    // }
    
    .app-name {
        margin-left: 2rem;
    }
    .app-name-0 {
        font-size: 3rem !important;
        padding: 1.5rem !important;
    }
    .h-logo {        
        width: 5rem;
        height: 5rem;
        >div {
            font-size: 3.25rem !important;
        }
    }

    .dev button.create-account:not(.button-as-link) {
        background-color: $color !important;
    }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        margin: 0 12rem !important;
        .leader {
            font-size: 4rem !important;
        }
        .subleader {
            max-width: 95%;
            font-size: calc(4rem * .666) !important;
            
            >div {
                font-size: calc(4rem * .45) !important;
                max-width: 100%;
                line-height: 3rem !important;
                margin: 3rem 0 0rem 0 !important;
            }
             span {
                 font-size: calc(4rem * .45) !important;
            }
            .callout {
                font-size: calc(4rem * .45) !important;
            }
        }
        .leaderlist {
            font-size: calc(4rem * .45) !important;
            li {
                font-size: calc(4rem * .45) !important;
            }
        }
        .my-dashboard {
            font-size:3rem !important;
        }
    }

    .default {
        .p-galleria-indicators {
            padding-right: 10rem !important;
            li {
                span {
                    font-size:1.75rem !important;
                }
            }
        }
        .p-galleria-content {
            background-size: 160% 100% !important;
            background-position-x: 55% !important;
            background-position-y: .5% !important;
            height: 100% !important;
        }
    }
    .p-overlaypanel:after {
        border-bottom-color: transparent !important;
    }

    .pi {
        font-size: $font-size;
    }

    .sidebar {
        box-shadow: inset -0.5rem 0 0 -3.5px $background-border;
    }

    .dashboard .grid .panel {
        width: 24.3% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 49.1% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74.1% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:7.25rem !important;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-scrollpanel-bar-y {
        width:.75rem;
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;
                
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }

    .p-carousel-prev,.p-carousel-next {
        display:none;
    }
    
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $input-border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
                td {
                    padding: 4rem .25rem !important;
                    
                }
            }

            .row-0, td:first-child {
                line-height:1.5rem;
            }

            td:first-child {
                line-height:1.5rem;
            }

            tr.table-row:last-of-type {
                height:4rem;
                font-size:$scale_1;
            }

            tr:first-of-type > td:last-of-type {
                width:5rem;
            }

            tr:last-of-type > td:last-of-type {
                font-size: 1.25em;
            }
        }
    }

    .panel {
        .card {
            border-radius: $borderRadius;
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }

    .ack-dlg {
        width: 50rem !important;
        position: absolute;
        bottom: 2.5px;
        float: right;
        .p-dialog-header {
            padding: 1rem;
        }
        .p-dialog-content {
            padding: 1.5rem;
            .consent{
                padding-top: 0 !important;
                line-height: 1.5rem;
                font-size:1.15rem;
            }
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1097.99px){
    $color: orange;
    //$base:4px;
    //$font-size: calc(#{$base} + 1vw);;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5rem;
    $knob-text-size: 2.5rem;
    $counter-text-size: 2.75rem;
    $arrow-adjust: -0.5rem;
    $map-width: 100%;   
    
    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 114%;
            }
        }
    }

    svg {
        overflow: visible !important;
    }

    

    // .powered-by {
    //     width: 15% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: -6rem !important;
    //     img {
    //         width: 150% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 2rem !important;
        .leader {
            font-size: 2.75rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: 1.75rem !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: 1.25rem !important;
            li {
                font-size: 1.25rem !important;
            }
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 2rem !important;
        .leader {
            font-size: 2.75rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: 1.75rem !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.25rem !important;
            }
        }
        .leaderlist {
            font-size: 1.25rem !important;
            li {
                font-size: 1.25rem !important;
            }
        }
    }

    .pi {
        font-size: $font-size;
    }

    .p-overlaypanel:after {
        border-bottom-color: transparent !important;
    }
    
    .sidebar {
        box-shadow: inset -0.45rem 0 0 -3.5px $background-border;
        max-width: calc(13% - 4px);
    }

    .dashboard .grid .title {
        white-space: break-spaces;
    }

    .dashboard .grid .panel {
        width: 24.275% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 49.25% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74.1% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:7.25rem !important;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-scrollpanel-bar-y {
        width:.75rem;
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }   

    .p-selectbutton .p-button {        
        padding: 0.75rem 1rem;
    }
    
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }
   
    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }
                        tr.table-row:last-of-type {
                font-size:$scale_1;
            }      
            tr:not(.row-0) {
                td {padding: 1.75rem .25rem !important;}
            }
        }
    }

    .panel {
        .card {            
            border-radius: $borderRadius;            
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }

    .ack-dlg {
        width: 50rem !important;
        position: absolute;
        bottom: 2.5px;
        float: right;

        .p-dialog-header {
            padding: 1rem;
        }

        .p-dialog-content {
            padding: 1.5rem;
            .consent{
                padding-top: 0 !important;
                line-height: 1.5rem;
                font-size:1.15rem;
            }
        }
    }
}

@media screen and (min-width: 1098px) and (max-width: 1279.99px){
    $color: gold;
    $base:5px;
    //$font-size:calc(#{$base} + 0.390625vw);
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5.333rem;
    $knob-text-size: 2.5rem;
    $counter-text-size: calc(#{$base} + 2vw);
    $arrow-adjust: -0.525rem;
    
    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 112.75%;
            }
        }
    }

    // .powered-by {
    //     width: 15% !important;
    //     border: 0px solid red; 
    //     left: -5rem !important;
    //     img {
    //         width: 150% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 2rem !important;
        .leader {
            font-size: 2.75rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: 1.75rem !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: 1.25rem !important;
            li {
                font-size: 1.25rem !important;
            }
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 2rem !important;
        .leader {
            font-size: 2.75rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: 1.75rem !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.25rem !important;
            }
        }
        .leaderlist {
            font-size: 1.25rem !important;
            li {
                font-size: 1.25rem !important;
            }
        }
    }

    .pi {
        font-size: $font-size;
    }
    
    .sidebar {
        box-shadow: inset -0.4rem 0 0 -3.5px $background-border;
    }

    .dashboard .grid .panel {
        width: 24.333% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 49.25% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74.2% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:2rem !important;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }

        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }        
    }
   
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }
   
    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }    
    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }
                        tr.table-row:last-of-type {
                font-size:$scale_1;
            }      
            tr:not(.row-0) {
                td {padding: 1.75rem .25rem !important;}
            }
        }
    }

    .panel {
        .card {            
            border-radius: $borderRadius;            
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    
    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1365.99px){
    $color: brown;
    $fontsize:1vw;
    $font-size: $fontsize !important;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5.333rem;
    $knob-text-size: 1.5em;
    $counter-text-size: calc(#{$font-size} + 1.5vw);
    $arrow-adjust: -0.525rem;

    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 111.5%;
            }
        }
    }

   
    // .powered-by {
    //     width: 45% !important;
    //     border: 0px solid red !important;
    //     position: relative !important;
    //     margin-left: 10rem !important;
    //     img {
    //         width: 75% !important;
    //     }
    // }

    .p-scrollpanel-content + .p-scrollpanel-bar-y {
        display: none;
    }

    .p-splitter-panel.content .p-scrollpanel-content {
        width: 100%;
    }

    #embed-container {
        height: 15rem !important;
    }

    .dev button.create-account:not(.button-as-link) {
        position: relative;
        //width: 17rem;
        left: -2rem;
        //top: -3.5rem;
        //background: red !important;
    }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .my-dashboard {
        font-size: 2rem;
    }
    .pi {
        font-size: $font-size;
    }

    .sidebar {
        box-shadow: inset -0.4rem 0 0 -3.5px $background-border;
    }

    .dashboard .grid .panel {
        width: 24.2% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 49% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:7.25rem !important;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;
    }

    .p-tabview.outer {        
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    } 
   
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }
   
    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }

            tr.table-row:last-of-type {
                font-size:$scale_1;
            }

            tr:not(.row-0) {
                td {padding: 1.75rem .25rem !important;}
            }
        }
    }
    
    .panel {
        .card {            
            border-radius: $borderRadius;            
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    
}

@media screen and (min-width: 1366px) and (max-width: 1529.99px){
    $color: olive;
    $scale_1: calc(#{$font-size} + .15rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5.333rem;
    $knob-text-size: 1.75rem;
    $counter-text-size: 2.75rem;
    $arrow-adjust: -0.475rem;
    
    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 111.5%;
            }
        }
    }

    

    // .powered-by {
    //     width: 15% !important;
    //     border: 0px solid red; 
    //     left: -5rem !important;
    //     img {
    //         width: 150% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 2rem !important;
        .leader {
            font-size: 2.75rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: 1.75rem !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: 1.25rem !important;
            li {
                font-size: 1.25rem !important;
            }
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 2rem !important;
        .leader {
            font-size: 2.75rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: 1.75rem !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.25rem !important;
            }
        }
        .leaderlist {
            font-size: 1.25rem !important;
            li {
                font-size: 1.25rem !important;
            }
        }
    }

    .pi {
        font-size: $font-size;
    }
    
    .sidebar {
        box-shadow: inset -0.35rem 0 0 -3.5px $background-border;
    }

    .dashboard .grid .panel {
        width: 24.3% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 49.2% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74.2% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:7.25rem !important;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-scrollpanel-bar-y {
        width:.666rem;
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;        
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }

        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }        
    }

    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }
                        
            tr.table-row:last-of-type {
                font-size:$scale_1;
            }

            tr:not(.row-0) {
                td {padding: 1.75rem .25rem !important;}
            }
        }
    }
       
    .panel {
        .card {
            border-radius: $borderRadius;            
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }
}
@media screen and (min-width: 1530px) and (max-width: 1629.99px){
    $color: yellow;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5.333rem;
    $knob-text-size: 1.5vw;
    $counter-text-size: calc(#{$font-size} + 1.5vw);
    $arrow-adjust: -0.525rem;
    
    .bi {
        .report {
            iframe {
                width: 100%;
                height: 109%;
            }
        }
    }
    
    // .powered-by {
    //     width: 15% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: -7.5rem !important;
    //     img {
    //         width: 150% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        .leader {
            font-size: 2.75rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: 1.5rem !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: 1.25rem !important;
            li {
                font-size: 1.25rem !important;
            }
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 2rem !important;
        .leader {
            font-size: 2.75rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: 1.5rem !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.25rem !important;
            }
        }
        .leaderlist {
            font-size: 1.25rem !important;
            li {
                font-size: 1.25rem !important;
            }
        }
    }

    .pi {
        font-size: $font-size;
    }
    
    .sidebar {
        box-shadow: inset -0.4rem 0 0 -3.5px $background-border;
    }

    .dashboard .grid .panel {
        width: 24.333% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 49.25% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74.2% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:2rem !important;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }

        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }        
    }
   
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }
   
    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }    
    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }
                        tr.table-row:last-of-type {
                font-size:$scale_1;
            }      
            tr:not(.row-0) {
                td {padding: 1.75rem .25rem !important;}
            }
        }
    }

    .panel {
        .card {            
            border-radius: $borderRadius;            
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }
}

@media screen and (min-width: 1630px) and (max-width: 1744.99px){
    $color: lime;
    $base:5px;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5.333rem;
    $knob-text-size: 1.75rem;
    $counter-text-size: 2.75rem;
    $arrow-adjust: -0.525rem;
    
    .bi {
        .report {
            iframe {
                width: 100%;
                height: 110.25%;
            }
        }
    }

    // .powered-by {
    //     width: 20% !important;
    //     border: 0px solid red; 
    //     position: relative !important; 
    //     left: -6rem !important;
    //     img {
    //         width: 125% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 2rem !important;
        .leader {
            font-size: 2.75rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: 1.75rem !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: 1.25rem !important;
            li {
                font-size: 1.25rem !important;
            }
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 2rem !important;
        .leader {
            font-size: 2.75rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: 1.75rem !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100%;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.25rem !important;
            }
        }
        .leaderlist {
            font-size: 1.25rem !important;
            li {
                font-size: 1.25rem !important;
            }
        }
    }

    .pi {
        font-size: $font-size;
    }
    
    .sidebar {
        box-shadow: inset -0.3rem 0 0 -3.5px $background-border;
    }

    .dashboard .grid .panel {
        width: 24.333% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 49.25% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74.2% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:2rem !important;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }

        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }        
    }
   
    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }
   
    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }    
    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }
                        tr.table-row:last-of-type {
                font-size:$scale_1;
            }      
            tr:not(.row-0) {
                td {padding: 1.75rem .25rem !important;}
            }
        }
    }

    .panel {
        .card {            
            border-radius: $borderRadius;            
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }    

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }
}

@media screen and (min-width: 1745px) and (max-width: 1920.99px){
    $color: $primary;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .75px;
    $borderRadius: $border-radius;
    $counter-margin: 5.15rem;
    $counter-text-size: 2.333vw;
    $knob-text-size: .333vw;
    $arrow-adjust: -0.5rem;
    $matrix-cell: 5.5rem;
    
    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
            }
        }
    }

    // .powered-by {
    //     width: 15% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: -9rem !important;
    //     img {
    //         width: 150% !important;
    //     }
    // }

    .p-scrollpanel-content + .p-scrollpanel-bar-y {
        display: none;
    }

    .p-splitter-panel.content .p-scrollpanel-content {
        width: 100%;
    }

    #embed-container {
        height: 15rem !important;
    }


    .dev button.create-account:not(.button-as-link) {
        background: $color !important;
    }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .pi {
        font-size: $font-size;
    }

    .p-knob-text {
        font-size: calc(#{$font-size} + #{$knob-text-size}) !important;
    }

    .default .p-galleria-item-container .p-galleria-item {
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .dashboard {
        .grid .panel {
            width: 24.33% !important;
        }

        .grid .panel.col-w-2 {
            width: 49.25% !important;
        }

        .grid .panel.col-w-3 {
            width: 74.2% !important;
        }

        .grid .panel.chart {
            margin-bottom:0rem !important;
        }

        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            +div {
                padding-right: 10rem
            }
        }
    }
    
    
    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;        
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }

    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }
   
    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }

    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }

            tr:not(.row-0):not(tr:last-of-type) {
                 td {
                     height: calc(100% / 7);
                 }
             }

            .row-0, td:first-child {
                line-height:1.5rem;
            }

            td:first-child {
                line-height:1.5rem;
            }

            tr.table-row:last-of-type {
                height:4rem;
                font-size:$scale_1;
            }

            tr:first-of-type > td:last-of-type {
                width:5rem;
            }

            tr:last-of-type > td:last-of-type {
                font-size: 1.25em;
            }  

            tr:not(.row-0) {
                td {padding: .25rem !important;}
            }

            tr.row-0 {
                td {padding: 0 !important;}
            }
        }
    }
    
    .panel {
        .card {            
            border-radius: $borderRadius;
            .title {
                font-size: $scale_1 !important;
            }
        }
    }

    .bio {
        .p-splitter-gutter {
            width:1px !important;
        }
        .p-carousel-items-content {
            max-width:95rem;
        }
    }
}

@media screen and (min-width: 1921px) and (max-width: 2047.99px){
    $color: coral;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5.333rem;
    $knob-text-size: calc(#{$font-size} + .333vw);
    $counter-text-size: calc(#{$font-size} + 1.5vw);

    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 110%;
            }
        }
    }

    // .powered-by {
    //     width: 26%; 
    //     border: 0px solid red; 
    //     position: relative; 
    //     left: 2rem; 
    //     img {
    //         width: 55% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .pi {
        font-size: $font-size;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .dashboard .grid .panel {
        width: 24.3% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 49.2% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74.1% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:7.25rem !important;
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }    

    .p-tooltip {
        .p-tooltip-arrow {
            left: -0.45rem;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }
   
    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }
   
    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }
                        
            tr.table-row:last-of-type {
                font-size:$scale_1;
            }

            tr:not(.row-0) {
                td {padding: 1.75rem .25rem !important;}
            }
        }
    }
    
    .panel {
        .card {            
            border-radius: $borderRadius;            
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }
}

@media screen and (min-width: 2048px) and (max-width: 2299.99px){
    $color: aqua;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .0675rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5.5rem;
    $knob-text-size: calc(#{$font-size} + .2vw);
    $counter-text-size: calc(#{$font-size} + 1.5vw);
    $arrow-adjust: -0.45rem;
    
    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 109.75%;
            }
        }
    }

    // .powered-by {
    //     width: 25% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: -4rem !important;
    //     img {
    //         width: 100% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 4rem !important;
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.25rem !important;
            }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .pi {
        font-size: $font-size;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;        
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }

    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }
   
    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }
    
    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }

    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }
    
    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }
    
    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }
    
    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }
    
    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }

            tr.table-row:last-of-type {
                font-size:$scale_1;
            }

            tr:not(.row-0) {
                td {padding: 1.75rem .25rem !important;}
            }            
        }
    }
    
    .panel {
        .card {            
            border-radius: $borderRadius;            
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }

    .ms-modal__content {
        padding: 2rem !important;
    }

    .ms-form__eye,
    .ms-form__eye-slash {
        width: 1.1rem !important;
    }

    .ms-modal__back button,
    .ms-modal__close button {
        width: .9rem !important;
        margin-right: .5rem !important;
        margin-top: .5rem !important;
        svg {
            height: 1rem;
            width: 1rem;
        }
    }

    .ms-modal__title {
        margin-bottom: 2rem !important;
    }

    .ms-form__button {
        margin: 2rem 0;
        border-radius: .333rem !important;
    }

    .ms-modal {
        width: 35rem !important;
    }

    .ms-form__group {
        margin-top: 2rem;
    }

    .ms-form__link {
        padding: .5rem !important;
        border-radius: .25rem !important;
    }

    .ms-form .ms-form__input {
        margin-top: 0.25rem !important;
        padding: unset;
        height: 3rem !important;
    }

    .ms-form__input--password,
    .ms-form__input {
        padding-left:.5rem !important;
    }
    
    .ms-form__input--token {
        border: 1px solid #414141 !important;
        padding: .25rem !important;
        max-width: 3rem !important;
    }

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }
}

@media screen and (min-width: 2300px) and (max-width: 2559.99px){
    $color: indigo;
    $base:10px;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .0675rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin: 5.5rem;
    $knob-text-size: calc(#{$font-size} + .1vw);
    $counter-text-size: calc(#{$font-size} + 1.5vw);
    $arrow-adjust: -0.45rem;
    
    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 109.25%;
            }
        }
    }

    // .powered-by {
    //     width: 22.5% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: -6rem !important;
    //     img {
    //         width: 120% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .pi {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 4rem !important;
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }
    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;        
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }

    .p-tooltip {
        .p-tooltip-arrow {
            left: $arrow-adjust;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }
   
    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }
    
    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }

    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }
    
    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }
    
    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }
    
    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }
    
    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }

            tr.table-row:last-of-type {
                font-size:$scale_1;
            }

            tr:not(.row-0) {
                td {padding: 1.75rem .25rem !important;}
            }            
        }
    }
 
    .panel {
        .card {            
            border-radius: $borderRadius;            
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .counter {
        font-size:$counter-text-size !important;
        display:inline-block;
        //margin:$counter-margin 0 $counter-margin .75rem;
    }
    
    .p-knob-text {
        font-size: $knob-text-size !important;
    }

    .ms-modal__content {
        padding: 2rem !important;
    }

    .ms-form__eye,
    .ms-form__eye-slash {
        width: 1.1rem !important;
    }

    .ms-modal__back button,
    .ms-modal__close button {
        width: .9rem !important;
        margin-right: .5rem !important;
        margin-top: .5rem !important;
        svg {
            height: 1rem;
            width: 1rem;
        }
    }

    .ms-modal__title {
        margin-bottom: 2rem !important;
    }

    .ms-form__button {
        margin: 2rem 0;
        border-radius: .333rem !important;
    }

    .ms-modal {
        width: 35rem !important;
    }

    .ms-form__group {
        margin-top: 2rem;
    }

    .ms-form__link {
        padding: .5rem !important;
        border-radius: .25rem !important;
    }

    .ms-form .ms-form__input {
        margin-top: 0.25rem !important;
        padding: unset;
        height: 3rem !important;
    }

    .ms-form__input--password,
    .ms-form__input {
        padding-left:.5rem !important;
    }
    
    .ms-form__input--token {
        border: 1px solid #414141 !important;
        padding: .25rem !important;
        max-width: 3rem !important;
    }

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }
}

@media screen and (min-width: 2560px) and (max-width:2879.99px) {
    $color: maroon;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $knob-text-size: calc(#{$font-size} + .1vw);
    $counter-text-size: calc(#{$font-size} + 1.5vw);

    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 108.5%;
            }
        }
    }

    // .powered-by {
    //     width: 20% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: -6rem !important;
    //     img {
    //         width: 125% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 4rem !important;
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 4rem !important;
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.25rem !important;
            }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .pi {
        font-size: $font-size;
    }

    .sidebar {
        box-shadow: inset -0.2rem 0 0 -3.5px $background-border;
    }

    .p-scrollpanel-bar {
        width:.666rem;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }
    
    .p-dialog:not(.inline-dialog){
        border: .5px solid $border !important;
    }

    .p-tabview.outer {

        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }
        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }    

    .p-tooltip {
        .p-tooltip-arrow {
            left: -0.45rem;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }
   
    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    
    
    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }
                        
            tr.table-row:last-of-type {
                font-size:$scale_1;
            }

            tr:not(.row-0) {
                td {padding: 1.65rem .25rem !important;}
            }
        }
    }
  
    .panel {
        .card {            
            border-radius: $borderRadius;
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }

    .ms-modal__content {
        padding: 2rem !important;
    }

    .ms-form__eye,
    .ms-form__eye-slash {
        width: 1.1rem !important;
    }

    .ms-modal__back button,
    .ms-modal__close button {
        width: .9rem !important;
        margin-right: .5rem !important;
        margin-top: .5rem !important;
        svg {
            height: 1rem;
            width: 1rem;
        }
    }

    .ms-modal__title {
        margin-bottom: 2rem !important;
    }

    .ms-form__button {
        margin: 2rem 0;
        border-radius: .333rem !important;
    }

    .ms-modal {
        width: 35rem !important;
    }

    .ms-form__group {
        margin-top: 2rem;
    }
    .ms-form__link {
        padding: .75rem !important;
        border-radius: .25rem !important;
    }
    .ms-form .ms-form__input {
        margin-top: 0.25rem !important;
        padding: unset;
        height: 3rem !important;
    }

    .ms-form__input--password,
    .ms-form__input {
        padding-left:.5rem !important;
    }

    .ms-form__input--token {
        width: 3rem !important;
        border: 1px solid #414141 !important;
        max-width: 3rem !important;
    }

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }
}

@media screen and (min-width: 2880px) and (max-width:3839.99px){
    $color: midnightblue;
    $base:16px;
    //$font-size:calc(#{$base} + 0.390625vw);
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin:5.4rem;
    $knob-text-size: .75rem;
    $counter-text-size: 2.75rem;

    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 107.75%;
            }
        }
    }

    // .powered-by {
    //     width: 15% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: -8rem !important;
    //     img {
    //         width: 160% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 4rem !important;
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 4rem !important;
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.25rem !important;
            }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .pi {
        font-size: $font-size;
    }

    .sidebar {
        box-shadow: inset -0.175rem 0 0 -3.5px $background-border;
    }

    .p-scrollpanel-bar-y {
        width:.666rem;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .dashboard .grid .panel {
        width: 24.3% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 49.2% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74.1% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:7.25rem !important;
    }

    .p-dialog:not(.inline-dialog) {
        border: .5px solid $border !important;
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }

        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }
   
    .p-tooltip {
        .p-tooltip-arrow {
            left: -0.45rem;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }
    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }
                        
            tr.table-row:last-of-type {
                font-size:$scale_1;
            }

            tr:not(.row-0) {
                td {padding: 1.75rem .25rem !important;}
            }
        }
    }

    .panel {
        .card {            
            border-radius: $borderRadius;            
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }

    .ms-modal__content {
        padding: 2rem !important;
    }

    .ms-form__eye,
    .ms-form__eye-slash {
        width: 1.1rem !important;
    }

    .ms-modal__back button,
    .ms-modal__close button {
        width: .9rem !important;
        margin-right: .5rem !important;
        margin-top: .5rem !important;
        svg {
            height: 1rem;
            width: 1rem;
        }
    }

    .ms-modal__title {
        margin-bottom: 2rem !important;
    }

    .ms-form__button {
        margin: 2rem 0;
        border-radius: .333rem !important;
    }

    .ms-modal {
        width: 35rem !important;
    }

    .ms-form__group {
        margin-top: 2rem;
    }
    .ms-form__link {
        padding: .75rem !important;
        border-radius: .25rem !important;
    }
    .ms-form .ms-form__input {
        margin-top: 0.25rem !important;
        padding: unset;
        height: 3rem !important;
    }

    .ms-form__input--password,
    .ms-form__input {
        padding-left:.5rem !important;
    }

    .ms-form__input--token {
        width: 3rem !important;
        border: 1px solid #414141 !important;
        max-width: 3rem !important;
    }

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }
}

@media screen and (min-width: 3840px) and (max-width:5760.99px){
    $color: orangered;
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin:5.4rem;
    $knob-text-size: .5rem;
    $counter-text-size: 2.8rem;

    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 106.75%;
            }
        }
    }

    // .powered-by {
    //     width: 15% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: -8rem !important;
    //     img {
    //         width: 160% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 4rem !important;
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 4rem !important;
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.25rem !important;
            }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .pi {
        font-size: $font-size;
    }

    .sidebar {
        box-shadow: inset -0.175rem 0 0 -3.5px $background-border;
    }

    .p-scrollpanel-bar-y {
        width:.5rem;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .dashboard .grid .panel {
        width: 24.3% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 49.2% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74.1% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:7.25rem !important;
    }

    .p-dialog:not(.inline-dialog) {
        border: .5px solid $border !important;
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }

        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }
   
    .p-tooltip {
        .p-tooltip-arrow {
            left: -0.45rem;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }
    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }
                        
            tr.table-row:last-of-type {
                font-size:$scale_1;
            }

            tr:not(.row-0) {
                td {padding: 1.75rem .25rem !important;}
            }
        }
    }

    .panel {
        .card {            
            border-radius: $borderRadius;            
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    

    .ms-modal__content {
        padding: 2rem !important;
    }

    .ms-form__eye,
    .ms-form__eye-slash {
        width: 1.1rem !important;
    }

    .ms-modal__back button,
    .ms-modal__close button {
        width: .9rem !important;
        margin-right: .5rem !important;
        margin-top: .5rem !important;
        svg {
            height: 1rem;
            width: 1rem;
        }
    }

    .ms-modal__title {
        margin-bottom: 2rem !important;
    }

    .ms-form__button {
        margin: 2rem 0;
        border-radius: .333rem !important;
    }

    .ms-modal {
        width: 35rem !important;
    }

    .ms-form__group {
        margin-top: 2rem;
    }
    .ms-form__link {
        padding: .75rem !important;
        border-radius: .25rem !important;
    }
    .ms-form .ms-form__input {
        margin-top: 0.25rem !important;
        padding: unset;
        height: 3rem !important;
    }

    .ms-form__input--password,
    .ms-form__input {
        padding-left:.5rem !important;
    }

    .ms-form__input--token {
        width: 3rem !important;
        border: 1px solid #414141 !important;
        max-width: 3rem !important;
    }

    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }
}

@media screen and (min-width: 5761px){
    $color: darkslategrey;
    $base:30px;
    //$font-size:calc(#{$base} + 0.390625vw);
    $scale_1: calc(#{$font-size} + .0775rem);
    $scale_2: calc(#{$scale_1} + .125rem);
    $btn-font-size: calc(#{$scale_1} - .125rem);
    $btn-border-size: .5px;
    $borderRadius: $border-radius;
    $counter-margin:5.4rem;
    $knob-text-size:.375rem;
    $counter-text-size:2.9rem;

    .bi {
        .report {
            iframe {
                position: relative;
                top: 0;
                width: 100%;
                height: 106.25%;
            }
        }
    }

    // .powered-by {
    //     width: 15% !important;
    //     border: 0px solid red; 
    //     position: relative !important;
    //     left: -8rem !important;
    //     img {
    //         width: 175% !important;
    //     }
    // }

    *:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.counter):not(.p-knob-text):not(.h-logo>div):not(.leader):not(.leaderlist):not(.leaderlist li):not(.subleader):not(.my-dashboard) {
        font-size: $font-size;
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 4rem !important;
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout { font-size: 1.25rem !important; }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .default .p-galleria-item-container .p-galleria-item {
        //margin: 0 4rem !important;
        .leader {
            font-size: 3rem !important;
        }
        .subleader {
            max-width: 100%;
            font-size: calc(2.25rem * .75) !important;
            >div {
                font-size: 1.25rem !important;
                max-width: 100;
            }
            span {
                font-size: 1.25rem !important;
            }
            .callout {
                font-size: 1.25rem !important;
            }
        }
        .leaderlist {
            font-size: calc(2.25rem * .5) !important;
            li {
                font-size: calc(2.25rem * .5) !important;
            }
        }
        .my-dashboard {
            font-size:1.75rem !important;
        }
    }

    .pi {
        font-size: $font-size;
    }

    .sidebar{
        box-shadow: inset -0.175rem 0 0 -3.5px $background-border;
    }

    .p-scrollpanel-bar-y {
        width:.75rem;
    }

    .p-button:not(.create-account):not(.login) {
        .p-button-label {
            font-size: $btn-font-size !important;
        }
    }

    .dashboard .grid .panel {
        width: 24.3% !important;
    }

    .dashboard .grid .panel.col-w-2 {
        width: 49.2% !important;
    }

    .dashboard .grid .panel.col-w-3 {
        width: 74.1% !important;
    }

    .dashboard .grid .panel.chart {
        margin-bottom:7.25rem !important;
    }

    .p-dialog:not(.inline-dialog) {
        border: .5px solid $border !important;
    }

    .p-tabview.outer {
        .p-tabview-title {
            font-size: $scale_2 !important;
        }
    }

    .p-tabview.inner {
        .p-tabview-nav {
            border-bottom: .5px solid $border !important;
        }

        .p-tabview-title {
            font-size: $scale_1 !important;
        }
    }

    .p-datatable {
        border:.5px solid $border;
        .p-datatable-thead {        
            tr  {
                border-top: .5px solid $border;
                border-bottom: .5px solid $border;
            }        
        }
    }
   
    .p-tooltip {
        .p-tooltip-arrow {
            left: -0.45rem;
            border-width: 0.75em 0.75em 0.75em 0;
        }
        .p-tooltip-text {
            line-height:calc(#{$font-size} + .5rem);
            box-shadow: inset 0 0 0 .5px $border !important;
        }
    }

    .p-input-icon-left > .p-inputtext {
        background: $input-background;
        &:hover,
        &:enabled:focus {
            border:0 !important;
            box-shadow: inset 0 0 0 .5px $border !important;
            background: $input-background !important;
        }
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }    .p-dropdown.p-inputwrapper {
        border: 1px solid $border;
    }

    .p-dropdown-items {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }
    .search:enabled {
        box-shadow: 0 0 0 .5px $border !important;
    }

    .matrix {
        height: 100%;
        tbody {
            tr {
                .data-cell {
                    font-size: $font-size;
                    line-height: 1.5rem;
                    width: 10rem;
                }
                td:first-of-type {
                    width: 8rem;
                }
            }
                        
            tr.table-row:last-of-type {
                font-size:$scale_1;
            }

            tr:not(.row-0) {
                td {padding: 1.75rem .25rem !important;}
            }
        }
    }

    .panel {
        .card {            
            border-radius: $borderRadius;            
            .title {
                font-size: $scale_1 !important;
            }
        }
    }
    
    .dashboard {
        .counter {
            font-size:$counter-text-size !important;
            display:inline-block;
            //margin:$counter-margin 0 $counter-margin .75rem;
        }
        .p-knob-text {
            font-size: $knob-text-size !important;
        }
    }
    

    .ms-modal__content {
        padding: 2rem !important;
    }

    .ms-form__eye,
    .ms-form__eye-slash {
        width: 1.1rem !important;
    }

    .ms-modal__back button,
    .ms-modal__close button {
        width: .9rem !important;
        margin-right: .5rem !important;
        margin-top: .5rem !important;
        svg {
            height: 1rem;
            width: 1rem;
        }
    }

    .ms-modal__title {
        margin-bottom: 2rem !important;
    }

    .ms-form__button {
        margin: 2rem 0;
        border-radius: .333rem !important;
    }

    .ms-modal {
        width: 35rem !important;
    }

    .ms-form__group {
        margin-top: 2rem;
    }
    .ms-form__link {
        padding: .75rem !important;
        border-radius: .25rem !important;
    }
    .ms-form .ms-form__input {
        margin-top: 0.25rem !important;
        padding: unset;
        height: 3rem !important;
    }

    .ms-form__input--token {
        width: 3rem !important;
        border: 1px solid #414141 !important;
        max-width: 3rem !important;
    }
    
    .p-input-icon-left > i, 
    .p-input-icon-right > i, 
    .p-input-icon-left > svg, 
    .p-input-icon-right > svg, 
    .p-input-icon-left > 
    .p-input-prefix, 
    .p-input-icon-right > 
    .p-input-suffix {
        position: absolute;
        top: 50%;
        margin-top: -0.35rem;
    }
}