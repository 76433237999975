.p-datatable {
    .p-datatable-wrapper {
        overflow-x: hidden;
    }
    .p-datatable-header {
        padding: .5rem 1rem;
    }
    .p-datatable-footer {
        padding: .5rem 1rem;
    }
    .p-datatable-thead > tr > th {
        padding: 1rem !important;
    }

    //border-radius: 4px;
    .p-sortable-column.p-highlight,
    .p-sortable-column.p-highlight .p-sortable-column-icon {
        background: #1f2d40;
        color: $primary-light;
    }

    .p-datatable-thead {
        tr  {
            border-top: 1px solid $border !important;
            background-color:$table-head !important;
        }        
    }

    .p-datatable-tbody > tr.p-row-odd { 
        background-color: $table-odd-row !important;
    }

    .p-datatable-tbody > tr > td {
        border-bottom: .5px solid $border;
        padding: .75rem 1rem;
    }
}

.p-datatable-loading-overlay {
    svg {
        color: $chart8;
    }
}
.row-head-table {
    display:inline;
    width:50%;
}

.column-icon,
.column-crud {
    background-color: #ffffff08 !important;
    text-align: center !important;
}

.column-icon i{
    font-size: 1.5rem;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled) .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled),
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: transparent;
    color: $primary-light;
}
.p-datatable .p-column-resizer {
    background: $column-resizer;
    width:.125rem;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:focus {
    outline: 0.1rem solid $primary;
    outline-offset: -0.1rem;
}

.p-datatable-footer {
    padding: .5rem;
    border-top:1px solid $border !important;
}

.p-datatable-header, .p-datatable-footer {
    background: $table-head !important;
}
.p-datatable .p-datatable-thead > tr > th {
    font-weight: 500;
}

.subtable-no-header {
    .p-datatable {
        border: 0 !important;
    }
    .p-datatable-thead {
        display:none;
    }
    
    .p-datatable-tbody {
        tr {
            td {
                padding-left:2.85rem !important;
            }
        }
    }
}