.ms-modal__outline-button,
.p-button:not(.create-account):not(.select-plan):not(.select-plan-selected):not(.no-bg):not(.p-sidebar-close){
    border-radius:4px;
    background: $button-bg !important;
    border: 0 !important;
    box-shadow: inset 0 0 0 0.05rem #454956 !important;
    &:hover:not(.create-account):not(.select-plan-selected):not(.p-link):not(.no-bg):not(.ms-modal__back>button):not(.ms-modal>button):not(.ms-form__button):not(.ms-modal__close>button:hover) {
        background: $button-bg-hover !important
    }
    &:focus:not(.no-bg):not(.ms-form__button):not(.p-button-icon-only):not(.p-link) {
        box-shadow: inset 0 0 0 1px $button-border !important;
    }
    .p-button-label {
        color: $text;
    }
    svg {
        color: $text;
    }
}

.select-plan, .select-plan-selected {
    border: 0 !important;
}
.p-menu.p-menu-overlay {
    padding: 0.05rem 0 !important;
    .p-menu-list {
        background: $button-bg;
    }    
}

.ms-modal__close>button {
    background: transparent !important;
    &:hover {
        background: transparent !important;
    }
}

.p-button.no-bg:hover:not(.expanded:hover):not(.collapsed:hover) {
    .p-button-icon {
        color: $text;
    }
}

.p-link:not(.p-carousel-indicator .p-link):not(.p-galleria-indicator .p-link) {
    background: transparent !important;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: $accent;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: $accent-dark;
}

.p-carousel-indicator .p-link:focus {
    box-shadow: 0 0 0 1px $border;
}

.p-selectbutton:not(.p-button-icon-only) {    
    border-radius:4px;
    .p-button {
        background: $button-bg !important;
        .p-button-label {
            transition-duration: unset;
        }
        padding: 0.5rem 1rem;
    }
    .p-highlight {
        .p-button-label {
            color: $primary-light !important;
            background: unset !important;
        }
    }
}

.p-button:enabled:active, 
.p-button:not(button):not(a):not(.p-disabled):active {
    background: unset;
    color: unset;
    border-color: unset;
}

.p-button-primary .p-button-label{
    color: $primary-light;
}

.p-button.p-highlight.p-focus,
.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px $border;
}

.p-button.p-highlight.p-focus,
.p-button:focus:not(.create-account):not(.no-border),
.p-button:not(.create-account):not(.no-border):not(.p-sidebar-close):not(.p-button:active) {
    box-shadow: inset 0 0 0 .05rem $button-border !important;
}
.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px $primary;
}

.p-button:enabled:active:not(.no-fx){
    transform: scale(0.96);
    .p-c {
        color: $button-press !important;
    }
}

.p-togglebutton.p-button.p-highlight,
.p-togglebutton.p-button.p-highlight:hover {
    color: $text;
}

.p-button-icon + .p-button-label{
    margin-left:.5rem;
}

.p-button-icon-only {
    padding: .5rem !important;
    .p-button-icon + .p-button-label{
        margin-left:0;
    }
    .p-icon {
        height: 1.2rem;
    }
}

.p-button-icon-only.p-highlight {
    svg path{
        fill: $accent;
    }
}
.p-splitbutton-defaultbutton {
    border-top-right-radius:0 !important;
    border-bottom-right-radius:0 !important;
}

.p-splitbutton-menubutton {
    border-top-left-radius:0 !important;
    border-bottom-left-radius:0 !important;
}

.p-menuitem-content {
    padding:1rem;
}

.p-tieredmenu {
    background: $button-bg !important;
    .p-menuitem-link {
        padding: .25rem;
    }
}

.p-menuitem-icon {
    margin-right:.5rem;
}

.p-link:focus {
    box-shadow: none;
}

.p-buttonset {
    .p-button:first-of-type {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }
    .p-button {
        border-radius: 0 !important;
    }
    .p-button:last-of-type {
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }
}
.linkAsButton {
    padding: .75rem;
    border: 1px solid $border;
    border-radius: 4px;
    background: $button-bg;
    color: $text !important;
}

.p-inputnumber-button-up {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.p-inputnumber-button-down {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.faux-button {
    cursor: default;
}
.p-button.login {
    padding: .5rem 1rem;

}