.p-tooltip {    
    .p-tooltip-arrow {
        border-right-color:$border !important;
    }
    .p-tooltip-text {
        box-shadow: inset 0 0 0 1px $border !important;
        background: lighten($background, 5%) !important;
        border: $border;
    }
}
