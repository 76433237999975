.key-anim10 {
    -webkit-animation: Drawpath 1s linear forwards;
    animation: Drawpath 1s linear forwards;
    stroke-dasharray: 0, 100;
    animation-delay: 2s;
  }
  .key-anim20 {
    -webkit-animation: Drawpath 1s linear 1s forwards;
    animation: Drawpath 1s linear 1s forwards;
    stroke-dasharray: 0, 100;
    animation-delay: 3s;
  }
  .key-anim30 {
    -webkit-animation: Drawpath 1s linear 2s forwards;
    animation: Drawpath 1s linear 2s forwards;
    stroke-dasharray: 0, 100;
    animation-delay: 4s;
  }
  
  .left {
      opacity:1
  }
  .center {
      opacity:1
  }
  .right {
      opacity:1
  }
  #logo {
    width:4.75rem;
  }
  .anim {
    height: 4.5rem;
    width: 4.5rem;
  }
  @-webkit-keyframes Drawpath {
    from {
      stroke-dasharray: 0, 100;
    }
    to {
      stroke-dasharray: 100, 100;
    }
  }
  @keyframes Drawpath {
    from {
      stroke-dasharray: 0, 100;
    }
    to {
      stroke-dasharray: 100, 100;
    }
  }