.p-card {
    .p-card-body {
        padding: 1rem;
        .p-card-title, .p-card-subtitle {
            margin-bottom: .5rem;
            font-size: 1.1rem;
        }
        
        .p-card-title {
            font-weight: 500 !important;
        }

        .p-card-subtitle {
            line-height: 1.25rem;
        }

        .p-card-content {
            //margin-top: 1rem;
            
        }
    }
}