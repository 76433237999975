html {
    overflow: hidden !important;
}

html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $text;
    background: $background !important;
    overflow: hidden !important;
    width: 100vw !important;
}

body:after{
    position:absolute; z-index:-1;
    display: none;
    content: url(./../images/landing-bg.png)
             url(./../images/at.svg)
             url(./../images/map.svg)
             url(./../images/manually-entered-data.svg)
             url(./../images/function.svg)
             url(./../images/third-party.svg)
             url(./../images/grouped-bar-chart.svg)
             url(./../images/heat-grid.svg) 
             url(./../images/graph.svg)
             url(./../images/user.svg)
             url(./../images/calculator.svg)
             url(./../images/landing-10.svg)             
             url(./../images/arrow-right.svg)
            ;
}

div:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.leader):not(.subleader),
span:not(.app-name):not(.app-name-0):not(.app-name-1):not(.pi):not(.leader):not(.subleader),
label,
input,
th,
td,
.p-inputtext,
input {
    font-family: $font;
    font-weight: $font-weight;
    font-size: $font-size;
    transition-duration: unset;
    
}

#base {
    padding-left: 1rem;
}
.vis-border {
    border: 1px solid $border;
}
.primary {
    color: $primary-light !important;
}
.accent {
    color: $accent !important;
}

.accent-light {
    color: $accent-light !important;
}

.important {
    color: $warning-color;
}

.pi {
    font-size: $font-size;
}

.bold {
    font-weight: 600 !important;
}

.italic {
    font-style: italic;
}

.vh-center {
    margin: auto;
    padding: 1rem;
}

.ui-prov-error {
    border: 1px solid $warning-color;
    color: $warning-color;
    padding: .333rem 1rem;
    text-align: center;
    width: fit-content;
}

.accented-border {
    border: 1px solid $accent;
    border-radius: $border-radius;
    legend {
        color: $accent;
        font-weight: 500;
    }
}

.button-as-link {
    border: 0 !important;
    background: transparent !important;
    padding-left: 0;
    .p-button-label {
        color: $accent;
        font-size: 1.5rem !important;
        &:hover {
            color: lighten($accent, 10%);
        }
    }
}

.no-border {
    border: 0 !important;
    box-shadow:none !important;
}

.panel {
    height: fit-content;
    .card {
        .title {
            font-weight:500 !important;
        }
    }    
}

.p-progress-spinner {
    position:absolute;
    left:50%;
    top:50%;
    .p-progress-spinner-svg {
        z-index: 9;
    }
}

a:not(a.underlined) {
    text-decoration:none !important;
}

a.link {
    color: $link !important;
}

a.underlined {
    color: $text !important;
}

a:visited:not(a.link):not(.override):not(.accent):not(.dropp):not(.ql-preview):not(.external-link) {
    color: $text !important;
}

.p-checkbox .p-checkbox-box {
    box-shadow: 0 0 0 1px $border;
    background: $input-background;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: 0 0 0 1px $border;
    border-color: $border;
}

.x-y-center {
    position: fixed; 
    top:50%; left:50%; 
    transform: translate(-50%, -50%)
}
.font-weight-500 {
    font-weight: 500;
}

.cursor-default {
    cursor:default;
}

.gated {
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
}

.dropp {
    font-style:italic;
    color: $text;
}

.ack-dlg {
    width: 60rem !important;
    position: absolute;
    bottom: .1rem;
    float: right;
    .p-dialog-header {
        padding: 1rem;
    }
    .p-dialog-content {
        padding: 1.5rem;
        .consent{
            padding-top: 0 !important;
            line-height: 1.5rem;
            font-size:1.15rem;
        }
    }
}

.no-bg {
    background:transparent !important;
    &:hover {
        background:transparent !important;
    }
}

.error-message {
    text-align: center;
    color: $error-color;
    line-height: 1.5rem;
}

.selected {
    color: $primary-light;
}

.hand {
    cursor: pointer;
}


.faux-form-footer {
    display:flex;
    justify-content:right;
    border-top: 0.1rem solid $border;
    padding-top: 1rem;
    button{
        width: unset !important;
        white-space: nowrap;
        margin-left: .5rem
    }
}

.progress {
    z-index: 9;
    margin-bottom: .2rem;
}

.no-vis {
    visibility: hidden;
}

.justify-space-between  {
    justify-content: space-between;
}

.item-loading {
    width: 7rem !important;
    height: 1.1rem !important;
}

.chart-loading {
    height: 80% !important;
}

.card {
    height: 100%;
}

.wrapper {
    height: 100%;
    overflow-y: hidden;
    .p-tabview {
        height: 100%;
        .p-tabview-panels:not(.wrapper.services .p-tabview-panels) {
            height: 100%;
            .p-tabview-panel {
                height: 100%;
                .section {
                    height: 88.5%;                    
                    border:.75px solid $section-border;
                    background: $section;
                    border-radius: $border-radius;
                    padding: 0;  /*.25rem .5rem */
                    .card_ {
                        height: 99%;
                        .flex_ {
                            height: 95%;
                        }
                    }
                }
            }
        }
    }
}

.data {
    font-family: $data-font !important;
    font-weight: $data-font-weight;
}


// .p-toolbar {
//     padding: 0 0.5rem;
// }

// .row-head-table {
//     td {
//         width:15rem;
//     }
// }


/// .p-scrollpanel-content:not(.dashboard > div > .p-scrollpanel-content) {
///     overflow-y: hidden !important;
/// }











// .p-highlight:not(.p-tabview-selected):not(.p-galleria-indicator):not(.p-checkbox-box) {
//     background: $highlight-light !important;
// }







// .p-timeline-event-separator {
//     margin: 0 1rem;
// }







// .p-progressbar {
//     background: $primary;
//     .p-progressbar-value {
//         background: $chart8 !important;
//     }
// }





@keyframes p-progress-spinner-color {
    100%,
    1% {
         stroke: $chart8;
    }
    50% {
        stroke: $chart8;
   }
    95% {
        stroke: #ff0000;
   }
}

// .progress {
//     padding-bottom: .1rem;    
    .vis {
        max-height: 0.1rem;
        min-height: 0.05rem;
        animation: rotate 5s infinite linear;
        -webkit-animation: rotate 6s infinite linear;
        background: transparent;

    }
    .pulse {    
        background: linear-gradient(to right,  
          $primary 0%,
          $primary 45%,
          $accent 50%,
          $primary 55%,
          $primary 100%);
    }
// }

@-webkit-keyframes rotate {
    from {
      background-position: -3150px;
    }
    to { 
      background-position: 0px;
    }
  }
  
  @keyframes rotate {
    from {
      background-position: -3150px;
    }
    to { 
      background-position: 0px;
    }
  }

::-webkit-scrollbar {
    width: .7rem;
    background: $scrollbar-track;
    -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
    background: $scrollbar;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px #000000bf;
}

::-webkit-scrollbar-corner {
    background: #000;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: $text;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

.not-found-animated {
    background-image: url(./../../styles/images/landing-10.svg);
    background-repeat: no-repeat;
    background-position: left bottom 0px;
    background-size: 110% 110%;
    background-position-x:initial;
    width: 50rem;
    background-position-y: center;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	// font-size: 100%;
	// font: inherit;
	// vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */


article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
