 .p-dialog:not(.create-account-dialog) {
    background: lighten($background, 2.5%) !important;
 }

 .p-dialog {
    border:0;    
    .p-dialog-header {
        padding:.75rem !important;
        border-bottom: .1rem solid $border;
        .p-dialog-title {
            font-weight: 500 !important;
            font-size:1.05rem !important;
            padding-left:.5rem;
        }
    }
    
    .p-dialog-footer {
            padding:.5rem;
            border-top: .1rem solid $border;
    }

    .p-dialog-content {        
        color: $text;
        padding: 1rem;
        
        .p-confirm-dialog-message {
            width: 25rem;
            line-height: 1.5rem;
        }
    }

    .p-dialog-header-icon {
        box-shadow:none !important;
    }

    .p-confirm-dialog-icon {
        font-size: 3rem !important;
        padding-right: 1rem;
    }
 }

 .confirm-delete {
    .pi {
        color: $warning-color;
    }
 }

 .inline-dialog {
    height: 82vh !important;
    background: transparent !important;
    border: 0 !important;
    box-shadow: none;
    margin-left: 10rem;
    margin-top: 7.5rem;
    .p-dialog-header {
        border-bottom: 0;
    }
}