// .p-checkbox {
//     height: 2rem !important;
//     width: 16px !important;
//     top: .05rem;
//     position: relative;
// }
.p-checkbox .p-checkbox-box.p-highlight {
    background: $primary-light !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: $primary;
    background: $primary;
}