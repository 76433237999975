@import 'primereact/resources/themes/vela-purple/theme.css';
@import "primereact/resources/primereact.min.css";
@import '/node_modules/primeflex/primeflex.css';
@import 'primeicons/primeicons.css';
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600&family=Urbanist:wght@300;400&family=Fira+Mono:wght@400&display=swap');    //&family=Sono:wght@300;400

$font: 'Jost';
$card-text-font: 'Jost';
$data-font: 'Fira Mono';
$data-font-weight: 400;
$font-weight:400;
$border-radius:.5rem;
$transitionDuration:.2s;
$font-size:.8vw;
$footer-height:0%; //5.5%

$base: #1d1e24;
$dustypurple: #472c63;
$white: #fff;
$bg: #463fa1;
$primary:  #756cef; //756cef;   //6c6fef;  //72E5FFE8  //00D0FF   //
$primary-light: lighten($primary, 10%);
$primary-dark: darken($primary, 5%);   // = 006880e8
$primary-hover: $primary-light;
$accent: #bad532;    //#afa9fd;    //ffa600; //96ff33b4;   //91ce72
$accent-light: lighten($accent, 25.5%);
$accent-dark: darken($accent, 30%);
$dropp: #27c3f0;

$text: #dddddd;
$dirty-text: #b1b1b1;
$wolf: #b8c1c5;
$required-color: #ed6f6f;
$false-color: #ed6f6f;
$true-color: #6fed80;
$info-color: #6fbaed;
$warning-color: #fe8d59;
$error-color: #fc5347;
$critical-color: #fc5347;
$success-color: $true-color;
$callout: $accent;
$title: $accent;
$border:lighten($base, 10%);

$highlight-light: #ffffff1a;
$highlight-dark: #ffffff00;
$input-border: #4d505f;
$row-border: #37393f;                           //3b3e49
$input-background: darken($base, 2.5%);
$input-border: lighten($input-background, 40%);
$input-background-hover: lighten($input-background, 5%);

$success-color: $true-color;
$positive: $true-color;
$negative: $false-color;
$increment: $true-color;
$decrement: $false-color;
$scrollbar: lighten($base, 20%);
$scrollbar-background:#4a4c53;
$scrollbar-track: darken($scrollbar-background, 12.5%);

$icon: $text;
$background: $base;
$background-dark: #161616;
$background-border: darken($background, 7.5%);
$background-border-light: lighten($background, 10%);

$sidebar: $background;
$sidebar-border: $background-border;
$section: lighten($background-dark, 0%);
$section-border: lighten($section, 0%);
$card: lighten($background, 1.5%);
$card-text: $dirty-text;
$card-border: lighten($background, 3%);

$overlay: lighten($background, 1.5%);
$overlay-border: lighten($overlay, 5%);
$glass: #4e4e4e40;
$glass-border: 1px solid #ffffff42;

$link: $accent;
$link-hover: $accent-light;
$nav-icon: $text;
$nav-item-border: lighten($sidebar, 10%);

$table-head: lighten($background-dark, 3%);
$table-foot: $table-head;
$table-odd-row: #24252b;
$column-resizer: #303030;

$button-bg: lighten($base, 7.5%);    //34363d
$button-bg-hover: lighten($button-bg, 3%);
$button-box-shadow:none;
$button-border:lighten($button-bg, 7.5%);
$button-press: darken($text, 15%);
$input-switch-bg: lighten($button-bg, 5%);

$menu-item-border: $border;
$network: $primary;;
$staking: $accent;
$separator:lighten($border, 10%);

$accordion-tab-active: #313238;

$chart1:#003f5c;
$chart2:#2f4b7c;
$chart3:#665191;
$chart4:#a05195;
$chart5:#d45087;
$chart6:#f95d6a;
$chart7:#ff7c43;
$chart8:#ffa600;

$knob: $chart8;
$knob-background: lighten($base, 20%);
$knob-text-size: calc(1.333rem);

$marker: $chart8;
$marker-selected: $chart6;
$marker-shadow: #000000b3;
$marker-inactive: #acacac;