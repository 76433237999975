

.admin.form {
    .editor-row {
        .p-editor-toolbar {
            background: $background;
        }
        .p-editor-toolbar {
            background: $background;
        }
        .ql-picker-label {
            color: #d1d1d1;
        }
        .ql-toolbar.ql-snow {
            border: 1px solid #3e3e3e;
            font-family: "Jost", "Helvetica Neue","Helvetica","Arial",sans-serif;
            padding: 8px;
        }
        .p-editor-container .ql-snow.ql-toolbar button.ql-active, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
            color: $primary-light;
        }
        .ql-transparent {
            opacity: .8 !important;
        }
        .ql-stroke, .ql-fill {
            stroke: $text !important;
            fill: #7a7a7ade !important;
        }
        .ql-picker-options {
            background: $background !important;
            border: $background !important;
        }
        .ql-tooltip.ql-editing {
            left: 10px !important;
        }
        .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
            border-color: $border;
        }
        .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before, .ql-snow .ql-picker.ql-header .ql-picker-item:before{
            color: $text;
        }
    }
}



 h1{
    font-size: xxx-large !important;
}
h2{
    font-size: xx-large !important;
}
h3{
    font-size: x-large !important;
}
h5 {
    font-size: small !important;
}
.ql-size-large {
    font-size: 1.1rem !important;
}
.ql-size-small {
    font-size: .9rem !important;
}
strong{
    font-weight: bold !important;
}

.p-editor-container .p-editor-content.ql-snow,
.p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid $border;
}