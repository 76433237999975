.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
    border: 1px solid $border !important;
    background: transparent !important;
    font-weight: 600;
    border-radius: 3px;
    transition: box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
    border: 1px solid $border !important;
    color: $text;
    background: transparent !important;
    font-weight: 600;
    border-radius: 3px;
    transition: box-shadow 0.2s;
}

.p-accordion .p-accordion-content {
    padding: 1rem;
    border: 1px solid $border;
    color: $text;
    background: $highlight-light;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}